/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import styled from 'styled-components';
import VerificationInput from 'react-verification-input';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../constants/breakpoints';
import { showToast } from '../../../hooks/show-toast';

import Button from '../../common/button/Button';
import Box from '../../common/box/Box';
import { colors } from '../../constant';
import Text from '../../common/text/Text';
import { LanguageSelector } from '../../common/language-selector';

import { getPermissions, verifyCode } from '../endpoints';

export function VerificationCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const { processSignIn } = useStoreActions(actions => actions.authentication);
  const { theme } = useStoreState(actions => actions.theme);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const handleCodeChange = codeVerify => {
    setCode(codeVerify);
  };

  const clearStateCredentials = () => {
    navigate(location.pathname, { replace: true });
  };

  const onSubmit = event => {
    event.preventDefault();
    setDisplayLoader(true);

    verifyCode(location.state?.username, location.state?.password, code).then(
      ([res]) => {
        if (res.success) {
          if (jwt(res.data.AccessToken)?.user?.IsFirstLogin) {
            processSignIn(res.data);
            getPermissions().then(([res]) => {
              localStorage.setItem('Permissions', JSON.stringify(res.data));
              clearStateCredentials();
              navigate('/reset-password');
              navigate(0);
            });
          } else {
            const hasKeys = res?.data?.HasRootAcessKey;
            const jwtToken = jwt(res.data.AccessToken);
            processSignIn(res.data);
            getPermissions().then(([res]) => {
              localStorage.setItem('Permissions', JSON.stringify(res.data));
              clearStateCredentials();
              if (!hasKeys && !jwtToken?.roles.includes('ROOT')) {
                navigate('/no-access-keys');
              } else {
                navigate(hasKeys ? '/buckets' : '/access-keys');
              }
            });
          }
        } else {
          setDisplayLoader(false);
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
          setCode('');
        }
      }
    );
  };

  return (
    <Box
      bgcolor={colors.lightGray}
      heightsize="100vh"
      display="flex"
      alignitems="center"
      justifycontent="center"
      mt="-60px"
    >
      <Box
        bgcolor={colors.white}
        borderradius="4px"
        minwidth={isMobile ? '75%' : '500px'}
        m="0 auto"
        p="30px"
        overflowy="auto"
        boxsizing="initial"
        maxheightsize="-webkit-fill-available"
      >
        <Box
          display="flex"
          direction="column"
          alignitems="center"
          justifycontent="center"
        >
          <Logo src={theme.logo} alt="" />
          <Text fontSize="18px" fontWeight="500" mb="20px" color={colors.black}>
            {t('enterVerificationCode')}
          </Text>

          <Text
            fontSize="14px"
            center="true"
            maxwidth="300px"
            textalign="center"
          >
            {t('inputVerificationCodeFromApp')}
          </Text>
        </Box>

        <form>
          <VerificationInput
            length={6}
            validChars="0-9"
            inputProps={{ type: 'tel' }}
            onChange={handleCodeChange}
            value={code}
            autoFocus={true}
            placeholder=""
            classNames={{
              container: 'verify-wrap',
              character: 'input-verify-code input-verify-code-login',
              characterInactive: 'input-verify-code--inactive',
              characterSelected: 'input-verify-code--selected'
            }}
          />

          <Button
            isLoading={displayLoader}
            disabled={code.length < 6}
            w="100%"
            primary
            onClick={onSubmit}
          >
            {t('verify')}
          </Button>

          <Text fontSize="14px" mt="20px" center="true">
            {t('backTo')} <Link href={'/login'}>{t('login')}</Link>
          </Text>
          <Box display="flex" justifycontent="center" mt="20px">
            <LanguageSelector />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

const Logo = styled.img`
  margin-bottom: 20px;
  max-width: 240px;
  max-height: 150px;
`;

const Link = styled.a`
  color: ${({ theme }) =>
    theme?.themes?.colorPrimary ?? colors.primary} !important;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  &:hover {
    color: ${({ theme }) =>
      theme?.themes?.colorPrimary ?? colors.primary} !important;
  }
`;

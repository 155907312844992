import React from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import Text from '../text/Text';
import i18n from '../../../i18n';
import Input from '../input/Input';
import { colors } from '../../constant';

export const TableHeaeder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
  }
`;

export const TableHeaederElement = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 767.98px) {
    margin-bottom: 10px;
  }
`;

export const TableHeaderSearch = styled(Input)`
  background-color: ${colors.softGray};
  min-width: 200px;
  border: 0;
  margin-right: 20px;
`;

export const Table = styled(DataGrid).attrs(
  ({ sx, componentProps, theme }) => ({
    sx: {
      border: 0,
      '& .MuiDataGrid-cell': {
        borderColor: '#F5F6F8',
        color: '#525E6B',
        cursor: 'pointer',

        '&:focus-within': {
          outline: 'none'
        }
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none'
      },

      '& .MuiCheckbox-root': {
        width: 13,
        height: 13,
        padding: 0,
        border: '1px solid #F1F1F1',
        borderRadius: '2px !important',
        backgroundColor: 'white'
      },
      '& .MuiCheckbox-root svg': {
        width: 7,
        height: 7,
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '1px'
      },
      '& .MuiCheckbox-root svg path': {
        display: 'none'
      },
      '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '1px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 13,
        height: 13
      },
      '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
        width: 13,
        height: 13,
        backgroundColor: 'inherit',
        transform: 'none',
        top: '39%',
        border: 0
      },

      '&.notFirstLastPage': {
        '.MuiPaginationItem-firstLast': {
          display: 'none'
        },

        '.MuiPagination-ul': {
          li: {
            border: '1px solid #F1F1F1',
            borderRight: 0,

            '.MuiPaginationItem-previousNext': {
              borderRight: 1,
              borderColor: '#F1F1F1',
              opacity: 1
            }
          }
        }
      }
    },

    componentsProps: {
      basePopper: {
        sx: {
          '& .MuiPaper-root': {
            boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)'
          },
          '& .MuiDataGrid-menuList': {
            paddingRight: '8px',
            paddingLeft: '8px',
            round: '0.4',
            color: '#6C757D'
          },
          '& .MuiMenuItem-root': {
            fontSize: '14px',
            padding: '10px',
            borderRadius: '4px'
          }
        }
      }
    }
  })
)`
  .MuiDataGrid-columnHeaders {
    background-color: #f5f6f8;
    border: 0;
    padding: 0 10px;
  }

  .MuiDataGrid-columnHeader {
    outline: none !important;
  }

  .MuiDataGrid-row {
    padding: 0 20px;
  }

  .MuiDataGrid-footerContainer {
    border: 0;
  }

  .MuiDataGrid-selectedRowCount {
    opacity: 0;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
    background-color: ${({ theme }) => theme.themes?.colorPrimary};
    border-color: ${({ theme }) => theme.themes?.colorPrimary};
  }
`;

export const noDataText = () => (
  <Text fontSize="14px" mt="10px" color={colors.bodyColor}>
    {i18n.t('thereIsCurrentlyNoData')}
  </Text>
);

export const noResultComp = {
  NoResultsOverlay: noDataText,
  NoRowsOverlay: noDataText
};

import React from 'react';

const BucketIcon = ({ fill = '#525E6B', width = '18', extraStyle = {} }) => {
  return (
    <svg
      style={extraStyle}
      width={width}
      height={width}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.995728 2.81941C0.995728 2.29466 3.71689 1.0495 8.99349 1.0495C14.2701 1.0495 16.9738 2.28577 16.9913 2.81052C16.9738 3.35305 14.2527 4.58933 9.00221 4.58933C3.72561 4.58933 1.00445 3.34416 1.00445 2.81941H0.995728ZM1.15272 4.31361C2.81855 5.21191 5.98452 5.63882 8.99349 5.63882C12.0025 5.63882 15.1597 5.21191 16.8255 4.31361L16.3546 8.79621C15.4737 9.50773 12.7525 10.2015 8.99349 10.2015C5.23445 10.2015 2.53074 9.50773 1.64113 8.79621L1.15272 4.30472V4.31361ZM1.78068 10.0858C3.31569 10.8063 5.98452 11.2599 8.99349 11.2599C12.0025 11.2599 14.6887 10.8063 16.215 10.0858L15.8226 13.7591C15.0987 14.435 12.5258 15.1199 8.90627 15.1199C5.47866 15.1199 3.09765 14.5151 2.18187 13.9103L1.77196 10.0858H1.78068ZM2.33014 15.1555C3.78666 15.7869 6.19384 16.1694 8.91499 16.1694C11.3658 16.1694 14.1131 15.8136 15.6917 15.0665L15.2295 19.4691C13.7817 18.6419 10.9123 18.384 9.00221 18.384C7.09217 18.384 4.25763 18.6419 2.80111 19.4602L2.33886 15.1643L2.33014 15.1555ZM2.89705 20.6875C3.0366 20.2517 5.29551 19.4246 9.00221 19.4246C12.7089 19.4246 14.994 20.2606 15.1074 20.6875C14.994 21.1056 12.7351 21.9416 9.00221 21.9416C5.29551 21.9416 3.0366 21.1145 2.89705 20.6787V20.6875ZM17.8896 2.29466C17.1221 0.720418 12.927 0 9.00221 0C5.07746 0 0.830016 0.729312 0.0973962 2.32135C-0.0683154 2.50812 0.0276229 3.37084 0.0276229 3.37084L1.20505 14.2305C1.20505 14.2305 1.20505 14.2749 1.20505 14.2927L1.88534 20.5808C1.88534 20.5808 1.86789 20.652 1.86789 20.6875C1.86789 22.5108 6.35083 23 9.00221 23C11.6536 23 15.9621 22.5286 16.1191 20.7854C16.1191 20.7676 16.1278 20.7587 16.1278 20.7409L17.9681 3.33527C17.9681 3.33527 18.0815 2.48144 17.8809 2.28577L17.8896 2.29466Z"
        fill={fill}
      />
    </svg>
  );
};

export default BucketIcon;

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import Badge from '../../common/badge';
import Text from '../../common/text/Text';
import styled from 'styled-components';
import LightTooltip from '../../common/tooltip/Tooltip';
import { colors } from '../../constant';
import { LabelInput } from '../../common/label/LabelInput';
import Title from '../../common/title/Title';

import i18n from '../../../i18n';
import { mdmobile } from '../../../constants/breakpoints';

export const localizedTextsMap = {
  columnMenuUnsort: i18n.t('unsort'),
  columnMenuSortAsc: i18n.t('columnMenuSortAsc'),
  columnMenuSortDesc: i18n.t('columnMenuSortDesc'),
  columnMenuFilter: i18n.t('columnMenuFilter'),
  columnMenuHideColumn: i18n.t('columnMenuHideColumn'),
  columnMenuShowColumns: i18n.t('columnMenuShowColumns'),
  filterOperatorContains: i18n.t('filterOperatorContains'),
  filterOperatorEquals: i18n.t('filterOperatorEquals'),
  filterOperatorStartsWith: i18n.t('filterOperatorStartsWith'),
  filterOperatorEndsWith: i18n.t('filterOperatorEndsWith'),
  filterOperatorIs: i18n.t('filterOperatorIs'),
  filterOperatorNot: i18n.t('filterOperatorNot'),
  filterOperatorAfter: i18n.t('filterOperatorAfter'),
  filterOperatorOnOrAfter: i18n.t('filterOperatorOnOrAfter'),
  filterOperatorBefore: i18n.t('filterOperatorBefore'),
  filterOperatorOnOrBefore: i18n.t('filterOperatorOnOrBefore'),
  filterOperatorIsEmpty: i18n.t('filterOperatorIsEmpty'),
  filterOperatorIsNotEmpty: i18n.t('filterOperatorIsNotEmpty'),
  filterOperatorIsAnyOf: i18n.t('filterOperatorIsAnyOf'),
  filterPanelInputLabel: i18n.t('value'),
  filterPanelInputPlaceholder: i18n.t('filterValue'),
  filterPanelColumns: i18n.t('columns'),
  columnsPanelTextFieldLabel: i18n.t('findColumn'),
  columnsPanelTextFieldPlaceholder: i18n.t('columnTitle'),
  filterPanelOperators: i18n.t('operator'),
  columnsPanelHideAllButton: i18n.t('hideAll'),
  columnsPanelShowAllButton: i18n.t('showAll'),
  columnHeaderSortIconLabel: i18n.t('sort'),
  checkboxSelectionHeaderName: i18n.t('checkboxSelectionHeaderName')
};

const rolesAndDescription = [
  {
    name: `${i18n.t('Root')}:`,
    description: `${i18n.t('rootDescriptionMembers')}`
  },
  {
    name: `${i18n.t('Administrator')}:`,
    description: `${i18n.t('administratorDescriptionMembers')}`
  },
  {
    name: `${i18n.t('Full Access')}:`,
    description: `${i18n.t('fullAccessDescriptionMembers')}`
  },
  {
    name: `${i18n.t('Full Access Limited')}:`,
    description: `${i18n.t('fullAccessLimitedDescriptionMembers')}`
  },
  {
    name: `${i18n.t('Download Only')}:`,
    description: `${i18n.t('downloadOnlyDescriptionMembers')}`
  },
  {
    name: `${i18n.t('Read Only')}:`,
    description: `${i18n.t('readOnlyDescriptionMembers')}`
  }
];

export const MemberToolTip = () => {
  const isMdMobile = useMediaQuery({ query: `(max-width: ${mdmobile}px)` });
  return (
    <Box minwidth={isMdMobile ? '300px' : '350px'}>
      {rolesAndDescription.map(item => (
        <Box key={item?.name} display="flex" style={{ flexWrap: 'wrap' }}>
          <Text color={colors.darkBlue} fontSize="14px" fontWeight="500">
            {item?.name}
            &nbsp;
          </Text>
          <Text color={colors.darkBlue} fontSize="14px" fontWeight="400">
            {item?.description}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export const MemberRenderer = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMdMobile = useMediaQuery({ query: `(max-width: ${mdmobile}px)` });

  const handleClick = e => {
    e.stopPropagation();
    if (isMdMobile) {
      setShowTooltip(!showTooltip);
    }
  };

  const handleMouseOver = () => {
    if (!isMdMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    if (!isMdMobile) {
      setShowTooltip(false);
    }
  };

  const handleOustideClick = event => {
    if (event.target.closest('.MuiTooltip-popper')) {
      setShowTooltip(false);
    }
  };

  document.addEventListener('click', handleOustideClick, true);

  return (
    <Box display="flex" alignitems="center">
      <Text fontWeight="500" fontSize="14px" color="#000000DE">
        {i18n.t('role')}
      </Text>
      <LightTooltip
        maxWidth={!isMdMobile && 'max-content'}
        minwidth="13px"
        title={MemberToolTip()}
        {...(!isMdMobile && { placement: 'bottom' })}
        open={(isMdMobile && showTooltip) || (!isMdMobile && showTooltip)}
        isMobile={isMdMobile}
      >
        <Box
          height="13px"
          display="flex"
          mt="1px"
          onClick={e => handleClick(e)}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Icon
            transform="scale(1.2)"
            icon="info_icon"
            width="13px"
            ml="5px"
            mb="1px"
          />
        </Box>
      </LightTooltip>
    </Box>
  );
};

export const MemberLabelRenderer = (filledField = false) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMdMobile = useMediaQuery({ query: `(max-width: ${mdmobile}px)` });

  const handleClick = e => {
    e.stopPropagation();
    if (isMdMobile) {
      setShowTooltip(!showTooltip);
    }
  };

  const handleMouseOver = () => {
    if (!isMdMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    if (!isMdMobile) {
      setShowTooltip(false);
    }
  };

  const handleOustideClick = event => {
    if (event.target.closest('.MuiTooltip-popper')) {
      setShowTooltip(false);
    }
  };

  document.addEventListener('click', handleOustideClick, true);

  return (
    <Box>
      <LabelInput
        fontSize={filledField && 12}
        className={
          filledField
            ? 'member-details-label filledField'
            : 'member-details-label'
        }
        id="storeLogsLabel"
        htmlFor="storeLogs"
        flex
      >
        {i18n.t('userRole')}*
        <LightTooltip
          maxWidth="unset"
          minwidth="13px"
          title={MemberToolTip()}
          placement={'bottom-start'}
          {...(!isMdMobile && { placement: 'bottom' })}
          open={(isMdMobile && showTooltip) || (!isMdMobile && showTooltip)}
          isMobile={isMdMobile}
        >
          <Box
            height="13px"
            display="flex"
            mt="2px"
            bgcolor="white"
            p="0 4px 0 0"
            onClick={e => handleClick(e)}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <Icon
              transform="scale(1.015)"
              icon="info_icon"
              width="13px"
              ml="5px"
              mb="1px"
            />
          </Box>
        </LightTooltip>
      </LabelInput>
    </Box>
  );
};

export const CreateMemberWithToolTip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMdMobile = useMediaQuery({ query: `(max-width: ${mdmobile}px)` });

  const handleClick = () => {
    if (isMdMobile) {
      setShowTooltip(!showTooltip);
    }
  };

  const handleMouseOver = () => {
    if (!isMdMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    if (!isMdMobile) {
      setShowTooltip(false);
    }
  };

  const handleOustideClick = event => {
    if (event.target.closest('.MuiTooltip-popper')) {
      setShowTooltip(false);
    }
  };

  document.addEventListener('click', handleOustideClick, true);

  return (
    <Box display="flex" alignitems="center">
      <Title fontSize="16px" fontWeight="500" color={colors.black}>
        {i18n.t('createMember')}
      </Title>
      <LightTooltip
        maxWidth={!isMdMobile && 'max-content'}
        minwidth="11px"
        title={MemberToolTip()}
        {...(!isMdMobile && { placement: 'bottom-start' })}
        open={(isMdMobile && showTooltip) || (!isMdMobile && showTooltip)}
        isMobile={isMdMobile}
      >
        <Box
          height="11px"
          display="flex"
          mt="2px"
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Icon
            transform="scale(1.2)"
            icon="info_icon"
            width="11px"
            ml="5px"
            mb="1px"
          />
        </Box>
      </LightTooltip>
    </Box>
  );
};

export const RenderMFA = ({ row }) => {
  const { mfa } = row;

  return (
    <Box ml="11px" flex justifycontent="center">
      <Icon
        width="14px"
        height="16px"
        display={mfa === 'Enabled' ? 'block' : 'none'}
        icon="mfa_enabled"
      />
    </Box>
  );
};

export const tableSx = {
  '& .MuiDataGrid-menuList': {
    paddingRight: '0',
    paddingLeft: '0',
    round: '0.4',
    color: '#6C757D',
    width: '221px',
    margin: '0 5px'
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold !important',
    overflow: 'visible !important',
    display: 'none !important'
  }
};

export const renderStatus = (status, timer = null) => {
  switch (status?.toUpperCase()) {
    case 'ACTIVE':
      return <Badge text={i18n.t('active')} />;
    case 'SUSPENDED':
      return (
        <Badge
          backgroundColor="#FFF3BB"
          color="#5D3115"
          text={i18n.t('suspended')}
        />
      );
    case 'LOCKED':
      return (
        <Badge
          backgroundColor="#FDE0DF"
          color="#5D3115"
          text={i18n.t('locked')}
        />
      );
    case 'DEACTIVATED':
      return (
        <Badge
          backgroundColor="#E6E7E8"
          color="#4A5158"
          text={i18n.t('deactivated')}
        />
      );
    case 'SUSPENDED-PROFILE':
      return (
        <Badge
          backgroundColor="#FFF3BB"
          color="#5D3115"
          text={i18n.t('suspended')}
          remainingTime={timer}
        />
      );
    default:
      return <Text>{i18n.t('noStatus')}</Text>;
  }
};

export const ReinstateMemberButton = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin: 20px 0;
`;

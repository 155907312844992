import React from 'react';

const CloseIcon = ({ fill = '#666666', width = '18' }) => {
  return (
    <svg
      width={width}
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98347 0L0 1.9788L2.00472 3.99293L4.00945 6.00707L2.00472 8.01413L0 10.0212L1.98347 12L3.98819 9.99293L5.99292 7.98587L7.99764 9.99293L10.0024 12L11.9858 10.0212L9.98111 8.01413L7.97639 6.00707L9.98819 4.00707L12 2.00707L10.0165 0.0282685L5.99292 4.02827L1.98347 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;

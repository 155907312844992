import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {
  OrganizationNameContainer,
  SSOConfigDescriptions,
  SSOConfigHeaderContainer,
  SSOConfigTitle
} from '../styles';
import Icon from '../../../../../common/icon/Icon';
import hasPermission from '../../../../../../security/Permission';
import { ButtonToggle } from '../../../../../common/button/ButtonToggle';
import { updateSSOConfigStatus } from '../../../../endpoints';
import { showToast } from '../../../../../../hooks/show-toast';
import { mobile } from '../../../../../../constants/breakpoints';

export default function SSOConfigHeader({
  isToggled,
  orgName,
  onClick,
  toggleSuccess
}) {
  const { t } = useTranslation();

  const editSSOPerm = hasPermission('oem_sso', 'connection');
  const [isToggling, setIsToggling] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const handleConnectionDelete = () => {
    setIsToggling(true);
    updateSSOConfigStatus(!isToggled).then(([res]) => {
      showToast(
        {
          message: res?.code || res?.Code,
          params: res?.params || null,
          success: res?.success
        },
        res?.success ? 'success' : 'error'
      );
      if (res?.success) toggleSuccess();
      setIsToggling(false);
    });
  };

  return (
    <>
      <SSOConfigHeaderContainer
        isToggled={isToggled}
        borderColor={!isToggled && 'transparent'}
      >
        <SSOConfigTitle>{t('ssoTitle')}</SSOConfigTitle>
        <ButtonToggle
          disabled={!editSSOPerm}
          isToggled={isToggled}
          handle={() => {
            if (!isToggling) handleConnectionDelete();
          }}
        />
      </SSOConfigHeaderContainer>

      {isToggled && (
        <>
          <SSOConfigDescriptions marginBottom="5px">
            {t('ssoDescription1')}
          </SSOConfigDescriptions>
          <SSOConfigDescriptions marginBottom="10px" fontWeight={500}>
            {t('organizationName')}
          </SSOConfigDescriptions>
          <OrganizationNameContainer isMobile={isMobile} disabled>
            {orgName}
            <Icon
              cursor={!editSSOPerm ? 'not-allowed' : 'pointer'}
              pathFill={!editSSOPerm && '#6c757d'}
              icon="edit"
              width="13px"
              height="13px"
              onClick={editSSOPerm ? onClick : null}
            />
          </OrganizationNameContainer>
        </>
      )}

      {isToggled && (
        <>
          <SSOConfigDescriptions marginBottom="5px">
            {t('ssoDescription2')}
          </SSOConfigDescriptions>
          <SSOConfigDescriptions fontWeight={500}>
            {t('selectConnectionType')}
          </SSOConfigDescriptions>
        </>
      )}
    </>
  );
}

SSOConfigHeader.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleSuccess: PropTypes.func.isRequired
};

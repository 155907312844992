import React from 'react';
import { useDropzone } from 'react-dropzone';
import fileDownload from 'js-file-download';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { mobile, xsmobile } from '../../../../../../constants/breakpoints';
import FormInput from '../../../../../common/form-input';
import { colors } from '../../../../../constant';
import Box from '../../../../../common/box/Box';
import Icon from '../../../../../common/icon/Icon';
import hasPermission from '../../../../../../security/Permission';
import Button from '../../../../../common/button/Button';
import { getSamlConfig } from '../../../../endpoints';

import {
  FileAttached,
  FileNameAndRemoveIcon,
  SSOConfigDescriptions,
  SamlConfigInputContainer,
  UploadFileButton,
  UrlText,
  UrlWrapper
} from '../styles';
import ConfigContainer from './ConfigContainer';

const SamlConfig = ({ formik, ssoConfigData }) => {
  const { t } = useTranslation();
  const editSSOPerm = hasPermission('oem_sso', 'connection');
  const ssoDomain = process.env.REACT_APP_SSO_DOMAIN;
  const ssoAudience = process.env.REACT_APP_SSO_AUDIENCE;
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const isXSMobile = useMediaQuery({ query: `(max-width: ${xsmobile}px)` });

  const { getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: false,
    noKeyboard: true,
    multiple: false,
    maxFiles: 1,
    disabled: !editSSOPerm,
    onDrop: file => {
      formik.setFieldValue('signingCert', file[0]);
    },
    accept: {
      'text/*': ['.cer', '.pem', '.cert']
    }
  });

  const onDownloadClick = () => {
    getSamlConfig()
      .then(([response]) => {
        fileDownload(response, 'ServiceProviderMetadata.xml');
      })
      .catch(error => {
        console.log('error');
      });
  };

  return (
    <ConfigContainer>
      <SamlConfigInputContainer padding="20px 0 0 0" borderColor={colors.gray}>
        <FormInput
          extraClassName="white-forminput"
          name="signInUrl"
          label={t('signInUrlLabel')}
          inputMarginBottom="20px"
          isRequired
          formik={formik}
          errorTop="calc(100% - 16px)"
          disabled={!editSSOPerm}
        />
        <FormInput
          label={t('signOutUrlLabel')}
          extraClassName="white-forminput mrg-top"
          name="signOutUrl"
          formik={formik}
          errorTop="calc(100% - 24px)"
          disabled={!editSSOPerm}
        />
        <SSOConfigDescriptions marginBottom="10px">
          {t('optionalField')}
        </SSOConfigDescriptions>
      </SamlConfigInputContainer>
      <SamlConfigInputContainer borderColor={colors.gray}>
        <SSOConfigDescriptions fontWeight="500" marginBottom="10px">
          {t('x509SigningCertificate')}
        </SSOConfigDescriptions>
        {formik?.values?.signingCert?.name?.length ? (
          <FileNameAndRemoveIcon>
            {formik?.values?.signingCert?.name}
            <Icon
              ml="10px"
              icon="x"
              width="20px"
              display={'block'}
              color="red"
              onClick={() => formik.setFieldValue('signingCert', '')}
            />
          </FileNameAndRemoveIcon>
        ) : (
          <>
            {ssoConfigData?.connectionType === 'SAMLP' && (
              <FileAttached>
                {t('fileAttached')}
                <Icon
                  width={'15px'}
                  icon={'green_checkmark'}
                  cursor="default"
                />
              </FileAttached>
            )}
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadFileButton disabled={!editSSOPerm}>
                {t('uploadFile')}
              </UploadFileButton>
            </Box>
          </>
        )}
        <SSOConfigDescriptions>
          {t('samlServerPublicKey')}
        </SSOConfigDescriptions>
      </SamlConfigInputContainer>
      <SamlConfigInputContainer borderColor={colors.gray}>
        <SSOConfigDescriptions fontWeight="500" marginBottom="5px">
          {t('idpConfiguration')}
        </SSOConfigDescriptions>
        <SSOConfigDescriptions>
          {t('configureBelowDetails')}
        </SSOConfigDescriptions>
        <UrlWrapper isXSMobile={isXSMobile}>
          <UrlText isMobile={isMobile}>{t('callbackUrl')}</UrlText>
          <a
            href={`${ssoDomain}/login/callback`}
            target="_blank"
            rel="noreferrer"
          >
            <UrlText
              isMobile={isMobile}
            >{`${ssoDomain}/login/callback`}</UrlText>
          </a>
        </UrlWrapper>
        <UrlWrapper isXSMobile={isXSMobile}>
          <UrlText isMobile={isMobile}>{t('audience')}</UrlText>
          <a href={`${ssoAudience}/api/v2/`} target="_blank" rel="noreferrer">
            <UrlText isMobile={isMobile}>{`${ssoAudience}/api/v2/`}</UrlText>
          </a>
        </UrlWrapper>
        <UrlWrapper isXSMobile={isXSMobile}>
          <UrlText isMobile={isMobile}>{t('userAttributes')}</UrlText>
          <UrlText isMobile={isMobile}>{t('passUserRole')}</UrlText>
        </UrlWrapper>
        <Button
          disabled={!editSSOPerm}
          disabledColor={!editSSOPerm && colors.bodyColor}
          mt="30px"
          isMobile={isMobile}
          onClick={() => onDownloadClick()}
        >
          <Box
            display="flex"
            alignitems="center"
            justifycontent="flex-start"
            gap="10px"
          >
            <Icon icon="download" width="20px" height="16px"></Icon>
            {t('downloadMetadata')}
          </Box>
        </Button>
      </SamlConfigInputContainer>
    </ConfigContainer>
  );
};

export default SamlConfig;

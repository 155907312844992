import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useMediaQuery } from 'react-responsive';

import ResetHeader from './components/ResetHeader';
import ResetForm from './components/ResetForm';

import Box from '../../common/box/Box';
import { colors } from '../../constant';
import { LoaderWrap } from '../../common/loader/Loader';

import { mobile } from '../../../constants/breakpoints';

export const ResetPassword = () => {
  const { resetLoader } = useStoreState(state => state.authentication);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  return (
    <Box
      bgcolor={colors.lightGray}
      heightsize="100vh"
      display="flex"
      alignitems="center"
      justifycontent="center"
      mt="-60px"
    >
      <Box
        boxsizing="initial"
        bgcolor={colors.white}
        borderradius="4px"
        maxwidth="380px"
        minwidth={isMobile ? '75%' : '380px'}
        m="0 auto"
        p="30px"
        overflowy="auto"
        maxheightsize="-webkit-fill-available"
      >
        <ResetHeader />
        <ResetForm />
      </Box>
      <LoaderWrap display={resetLoader} />
    </Box>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Box from '../../common/box/Box';
import Title from '../../common/title/Title';
import Icon from '../../common/icon/Icon';
import Line from '../../common/line/Line';
import Label from '../../common/label/Label';
import Input from '../../common/input/Input';
import Text from '../../common/text/Text';
import hasPermission from '../../../security/Permission';
import {
  deleteBucketObjects,
  downloadFile,
  getObjectDetails,
  renameFile,
  toggleLegalHolding,
  toggleShowVersions
} from '../endpoints';
import { colors } from '../../constant';
import { LoaderWrap } from '../../common/loader/Loader';
import { showToast } from '../../../hooks/show-toast';
import { ButtonToggle } from '../../common/button/ButtonToggle';
import { ErrorMessage } from '../../common/error-message';
import { formatDateWithUtc } from '../../../utils/format.dates.utils';

import { validationSchema } from './helper';
import ConfirmLegalHoldEnable from './components/ConfirmLegalHoldEnable';
import ObjectLocking from './components/ObjectLocking';
import FileActions from './components/FileActions';
import { mobile } from '../../../constants/breakpoints';

const preventClickPropagation = e => {
  e.stopPropagation();
};

const BucketObjectDetails = ({
  objectName,
  selectedRowVersionId,
  backgroundcloses = true,
  close,
  open,
  onDeleteConfirm,
  onRenameFile,
  setIsSearchingTable,
  setSearchValue,
  setIsVersioningToggled,
  isVersioningToggled,
  setToggleRefetch,
  setLoading,
  searchValue,
  isSearchingTable
}) => {
  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);
  const { legalHoldToggle } = useStoreState(actions => actions.fileDetails);
  const { setLegalHoldToggle } = useStoreActions(
    actions => actions.fileDetails
  );
  const { name: objName } = useParams();
  const canDownloadFilePerm = hasPermission('oem_objects', 'download_file');
  const canUpdateFilePerm = hasPermission('oem_objects', 'update_file');
  const canDeleteFilePerm = hasPermission('oem_objects', 'delete_file');
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const [response, setResponse] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [fileNameEnabled, setFileNameEnabled] = useState(false);
  const [fileName, setFileName] = useState('');
  const [copyFileName, setCopyFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [lastModified, setLastModified] = useState('');
  const [versionId, setVersionId] = useState('');
  const [confrimLegalHoldView, setConfrimLegalHoldView] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLocked, setEditingLocked] = useState(false);
  const { name, '*': directory } = useParams();
  const [legalHoldLoading, setLegalHoldLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const objectNameDir = directory
    ? directory.concat('/').concat(objectName)
    : objectName;
  const fileNameDir = directory
    ? copyFileName?.indexOf('/') > -1
      ? copyFileName
      : directory.concat('/').concat(copyFileName)
    : copyFileName;

  const onDeleteClick = () => {
    setDeleteClicked(true);
  };

  const onCancelDeleteClick = () => {
    setDeleteClicked(false);
  };

  const onDeleteConfirmClick = () => {
    setDeleteLoader(true);
    deleteBucketObjects(name, [
      {
        name: fileNameDir,
        versionId: response?.versionId
      }
    ]).then(([res]) => {
      setDeleteLoader(false);
      if (res.success) {
        onDeleteConfirm();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
    });
  };

  const onDownloadFileClick = () => {
    setDisplayLoader(true);
    downloadFile(name, fileNameDir, versionId).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        window.open(res.data.Url, '_blank');
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
    });
  };

  const onCopyPathClick = () => {
    navigator.clipboard.writeText(fileNameDir);
    showToast(
      { message: t('pathCopiedToClipboard'), customMessage: true },
      'info'
    );
  };

  const fetchBucketObjectDetails = (name, objectNameDir, versionIdParam) => {
    setDisplayLoader(true);
    getObjectDetails(
      name,
      objectNameDir,
      versionIdParam || selectedRowVersionId
    ).then(([res]) => {
      setCopyFileName(objectNameDir);
      setDisplayLoader(false);
      setFileNameEnabled(false);
      if (res.success) {
        setResponse(res.data);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
      }
    });
  };

  useEffect(() => {
    if (
      open &&
      (!response ||
        response.name !== objectName ||
        versionId !== selectedRowVersionId)
    ) {
      fetchBucketObjectDetails(name, objectNameDir, selectedRowVersionId);
    } else {
      cancelUpdateName();
    }
  }, [open, selectedRowVersionId]);

  useEffect(() => {
    if (response) {
      setFileName(response.name);
      setCopyFileName(response.name);
      setFileSize(response.fileSize);
      setLastModified(response.lastModified);
      setVersionId(response.versionId);
      setFileNameEnabled(false);
      setLegalHoldToggle(response.legalHold?.enabled);
      if (confrimLegalHoldView)
        setLegalHoldToggle(!response.legalHold?.enabled);
    }
  }, [response]);

  const onChange = e => {
    setFileName(e.target.value);
  };

  const updateFileName = () => {
    setDisplayLoader(true);
    if (['%', '<', '>', '/'].some(item => fileName.indexOf(item) > -1)) {
      showToast(
        {
          message: t('cannotRenameObjectError'),
          success: false,
          customMessage: true
        },
        'error'
      );
      setFileName(response?.name);
      setCopyFileName(response?.name);
      setFileNameEnabled(false);
      return;
    }

    const dir = response.key.replace(response.name, '');

    renameFile(name, dir, response.name, fileName, versionId).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        if (isSearchingTable || searchValue?.length > 0) {
          setIsSearchingTable(true); // Reset search state
        } else {
          setIsSearchingTable(false);
        }
        setFileNameEnabled(false);
        if (res?.data)
          fetchBucketObjectDetails(name, dir + fileName, res?.data?.versionId);
        onRenameFile();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
    });
  };

  const cancelUpdateName = () => {
    setFileNameEnabled(false);
    setFileName(response?.name);
    setCopyFileName(response?.name);
  };

  const handleViewAllVersionsClick = () => {
    setLoading(true);
    setIsSearchingTable(true);
    setSearchValue(copyFileName || objectName);
    setIsVersioningToggled(true);
    setToggleRefetch(prev => {
      return !prev;
    });
    if (!isVersioningToggled) {
      toggleShowVersions();
    }
    setTimeout(() => {
      close();
    }, 300);
  };

  const handleLegalToggleView = () => {
    setLegalHoldLoading(true);
    const payload = {
      enabled: legalHoldToggle,
      versionId: versionId
    };

    toggleLegalHolding(objName, fileNameDir, payload).then(([res]) => {
      if (res.success) {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setConfrimLegalHoldView(false);
        setResponse({ ...response, legalHold: res.data });
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: false
          },
          'error'
        );
      }
      setLegalHoldLoading(false);
    });
  };

  const formik = {
    ...useFormik({
      validationSchema: validationSchema,
      initialValues: { fileName: fileName },
      enableReinitialize: true,
      onSubmit: () => {
        updateFileName();
      }
    })
  };

  return (
    <ObjectDetailsView
      className={open ? 'active-box' : ''}
      onClick={
        backgroundcloses
          ? () => {
              close();
              setConfrimLegalHoldView(false);
            }
          : null
      }
    >
      <Box
        relative="true"
        className="box-inner"
        onClick={preventClickPropagation}
      >
        <StyledContainer isMobile={isMobile}>
          <Box
            display="flex"
            alignitems="center"
            justifycontent="space-between"
          >
            <Title fontSize="16px" fontWeight="500" color={colors.black}>
              {t('fileDetails')}
            </Title>
            <Icon
              width="18px"
              height="18px"
              icon="x"
              onClick={() => {
                close();
                setConfrimLegalHoldView(false);
              }}
            />
          </Box>

          <Line m="20px 0" />

          <form>
            <Box display="flex" alignitems="flex-start" direction="column">
              <Label fontWeight="500">{t('fileName')}</Label>{' '}
              <Box display="flex" alignitems="center" w="100%">
                <Input
                  size="0 20px 0 0"
                  marginBottom="0"
                  width="100%"
                  value={fileName}
                  readOnly={!(fileNameEnabled && canUpdateFilePerm)}
                  fontSize="14px"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onChange={onChange.bind(this)}
                  name="fileName"
                />
                {canUpdateFilePerm && (
                  <Icon
                    icon="edit"
                    width="20px"
                    height="20px"
                    onClick={() => setFileNameEnabled(true)}
                    display={fileNameEnabled ? 'none' : 'initial'}
                  />
                )}

                <Box
                  display={fileNameEnabled ? 'flex' : 'none'}
                  alignitems="center"
                >
                  <Icon
                    icon="checkedIcon"
                    width="15px"
                    height="15px"
                    mr="10px"
                    onClick={canUpdateFilePerm ? formik.handleSubmit : null}
                  />
                  <Icon
                    icon="x"
                    width="16px"
                    height="16px"
                    color={colors.errorColor}
                    onClick={cancelUpdateName}
                  />
                </Box>
              </Box>
              <ErrorMessage>{formik?.errors.fileName}</ErrorMessage>
              <Box mb="20px"></Box>
            </Box>
          </form>
          <Box mb="30px">
            <Label fontWeight="500">{t('fileSize')}</Label>{' '}
            <Text fontSize="14px">{fileSize}</Text>
          </Box>

          <Box mb="30px">
            <Label fontWeight="500">{t('lastModified')}</Label>{' '}
            <Text fontSize="14px">{formatDateWithUtc(lastModified)}</Text>
          </Box>

          <Box mb="30px">
            <Box display={'flex'} justifycontent={'space-between'}>
              <Label fontWeight="500">{t('versionId')}</Label>{' '}
              <Label
                onClick={() => handleViewAllVersionsClick()}
                cursor={'pointer'}
                color={theme?.themes?.colorPrimary || colors.blue}
                fontWeight="500"
              >
                {t('viewAllVersions')}
              </Label>
            </Box>
            <Text fontSize="14px">
              {versionId.length > 0 ? versionId : t('versionIdNotAvailable')}{' '}
            </Text>
          </Box>
          {Boolean(response?.canLockObject) && (
            <ObjectLocking
              canUpdateFilePerm={canUpdateFilePerm}
              setDeleteClicked={setDeleteClicked}
              setResponse={setResponse}
              fileNameDir={fileNameDir}
              open={open}
              editingLocked={editingLocked}
              setEditingLocked={setEditingLocked}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              response={response}
            />
          )}
          {!isEditing && !editingLocked && Boolean(response?.canLockObject) && (
            <Box mb={'30px'} display={'flex'} justifycontent={'space-between'}>
              <Label fontWeight="500">{t('legalHold')}</Label>{' '}
              <ButtonToggle
                disabled={!response?.canLockObject || !canUpdateFilePerm}
                type="button"
                handle={() => {
                  setConfrimLegalHoldView(true);
                  if (!confrimLegalHoldView)
                    setLegalHoldToggle(!legalHoldToggle);
                  else {
                    setLegalHoldToggle(!legalHoldToggle);
                    setConfrimLegalHoldView(false);
                  }
                }}
                isToggled={legalHoldToggle}
              />
            </Box>
          )}
          {confrimLegalHoldView && (
            <ConfirmLegalHoldEnable
              isLoading={legalHoldLoading}
              fileName={fileName}
              handleLegalToggleView={handleLegalToggleView}
              setConfrimLegalHoldView={setConfrimLegalHoldView}
              toggle={() => setLegalHoldToggle(!legalHoldToggle)}
            />
          )}
          {!confrimLegalHoldView && !isEditing && !editingLocked && (
            <FileActions
              deleteLoader={deleteLoader}
              displayLoader={displayLoader}
              canDeleteFilePerm={canDeleteFilePerm}
              canDownloadFilePerm={canDownloadFilePerm}
              deleteClicked={deleteClicked}
              onCancelDeleteClick={onCancelDeleteClick}
              onCopyPathClick={onCopyPathClick}
              onDeleteClick={onDeleteClick}
              onDeleteConfirmClick={onDeleteConfirmClick}
              onDownloadFileClick={onDownloadFileClick}
              theme={theme}
            />
          )}
        </StyledContainer>
        <LoaderWrap display={displayLoader} />
      </Box>
    </ObjectDetailsView>
  );
};

const ObjectDetailsView = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 7;
  .box-inner {
    height: 100%;
    width: 0;
    transition: width 0.5s ease-in-out;
    background-color: ${colors.white};
  }

  &.active-box {
    opacity: 1;
    visibility: visible;

    .box-inner {
      width: 403px;
    }
  }
`;

const StyledContainer = styled(Box).attrs({
  heightsize: '88vh',
  mt: '60px',
  p: '30px',
  overflowy: 'auto',
  maxheightsize: '-webkit-fill-available'
})`
  padding-bottom: ${props => props.isMobile && '100px'};
`;

BucketObjectDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  isVersioningToggled: PropTypes.bool.isRequired,
  isSearchingTable: PropTypes.bool.isRequired,
  backgroundcloses: PropTypes.bool.isRequired,
  objectName: PropTypes.string.isRequired,
  selectedRowVersionId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRenameFile: PropTypes.func.isRequired,
  setIsSearchingTable: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setIsVersioningToggled: PropTypes.func.isRequired,
  setToggleRefetch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired
};

export default BucketObjectDetails;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { colors } from '../../constant';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement={props.placetop}
    {...props}
    classes={{ popper: className }}
    PopperProps={
      props.isMobile
        ? {
            sx: {
              '&': {
                minWidth: '100%'
              }
            }
          }
        : {}
    }
  />
))(({ theme, maxWidth, minWidth, isMobile }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.softGray,
    boxShadow: '0 0 18px rgb(0 0 0 / 30%)',
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    padding: 15,
    whiteSpace: 'initial',
    transition: 'opacity 0.2s',
    borderRadius: 6,
    maxWidth: maxWidth,
    minWidth: minWidth || '',
    fontFamily: 'Roboto, sans-serif;',
    marginInline: isMobile ? 'auto' : ''
  }
}));

export default LightTooltip;

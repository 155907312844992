import React from 'react';

const LogOutIcon = ({ fill = '#666666', width = '18', extraStyle = {} }) => {
  return (
    <svg
      style={extraStyle}
      width={width}
      height={width}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 13.167V10.667H5.5V7.33366H11.3333V4.83366L15.5 9.00033L11.3333 13.167ZM9.66667 0.666992C10.1087 0.666992 10.5326 0.842587 10.8452 1.15515C11.1577 1.46771 11.3333 1.89163 11.3333 2.33366V4.00033H9.66667V2.33366H2.16667V15.667H9.66667V14.0003H11.3333V15.667C11.3333 16.109 11.1577 16.5329 10.8452 16.8455C10.5326 17.1581 10.1087 17.3337 9.66667 17.3337H2.16667C1.72464 17.3337 1.30072 17.1581 0.988155 16.8455C0.675595 16.5329 0.5 16.109 0.5 15.667V2.33366C0.5 1.89163 0.675595 1.46771 0.988155 1.15515C1.30072 0.842587 1.72464 0.666992 2.16667 0.666992H9.66667Z"
        fill={fill}
      />
    </svg>
  );
};

export default LogOutIcon;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import {
  AccountInformationLabelInputContainer,
  AccountInformationDataContainer,
  AccountInformationInputs
} from './styles';

import { colors } from '../../../../constant';
import { LabelInput } from '../../../../common/label/LabelInput';
import TextAvatar from '../../../../header/TextAvatar';
import CheckboxWrapper from '../../../../common/checkbox/CheckboxWrapper';
import FormInput from '../../../../common/form-input';
import { getSubAccount } from '../../../../auth/endpoints';
import { mobile } from '../../../../../constants/breakpoints';

const AccountInformationData = () => {
  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const [accountData, setAccountData] = useState({
    //TODO - Remove name when backend is ready
    name: '',
    firstName: '',
    lastName: '',
    number: '',
    email: '',
    createTime: '',
    active: false
  });

  useEffect(() => {
    getSubAccount().then(([res]) => {
      if (res?.success) {
        setAccountData({
          //TODO - Remove name setter when backend is ready
          name: res?.data?.Partner?.Name,
          firstName: res?.data?.Partner?.FirstName,
          lastName: res?.data?.Partner?.LastName,
          number: `${res?.data?.Number}`,
          email: res?.data?.Name,
          createTime: moment(res?.data?.CreateTime)?.format('DD-MMM-YYYY'),
          active: res?.data?.Active
        });
      }
    });
  }, []);

  return (
    <AccountInformationDataContainer isMobile={isMobile}>
      <div>
        <TextAvatar
          bgColor={colors.softGray}
          textColor={theme.themes?.primary}
          size={190}
          showTextAvatar
          round="0"
          //TODO - change this to accoundData.firstName when backend is ready
          name={accountData?.name?.split(' ')?.[0] || ''}
          //TODO - change this to accoundData.lastName when backend is ready
          lastName={accountData?.name?.split(' ')?.[1] || ''}
        />
        <AccountInformationLabelInputContainer>
          <CheckboxWrapper cursor="not-allowed">
            <input
              type="checkbox"
              id="active-input"
              checked={accountData?.active}
            />
            <LabelInput
              fontSize="14px"
              id="active-label"
              htmlFor="active-input"
            >
              {t('active')}
            </LabelInput>
          </CheckboxWrapper>
        </AccountInformationLabelInputContainer>
      </div>
      <AccountInformationInputs isMobile={isMobile}>
        <FormInput
          label={t('accountName')}
          disabled
          value={accountData?.name}
          name="accountName"
          inputMarginBottom="20px"
        />
        <FormInput
          label={t('accountEmail')}
          disabled
          value={accountData?.email}
          name="accountEmail"
          inputMarginBottom="20px"
        />
        <FormInput
          label={t('storageAccountNumber')}
          disabled
          value={accountData?.number}
          name="storageAccountNumber"
          inputMarginBottom="20px"
        />
        <FormInput
          label={t('creationTime')}
          disabled
          value={accountData?.createTime}
          name="creationTime"
          inputMarginBottom="20px"
        />
      </AccountInformationInputs>
    </AccountInformationDataContainer>
  );
};

export default AccountInformationData;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import BucketIcon from '../../assets/icons/BucketIcon';
import UsageIcon from '../../assets/icons/UsageIcon';
import SettingsIcon from '../../assets/icons/SettingsIcon';
import LogOutIcon from '../../assets/icons/LogOutIcon';
import MenuIcon from '../../assets/icons/MenuIcon';
import CloseIcon from '../../assets/icons/CloseIcon';

import { LogoWrapper } from '../common/header/HeaderWrapper';
import { LogoStyle } from '../common/header/HeaderWrapper';
import { LanguageSelector } from '../common/language-selector';
import { NavbarLink } from '../common/header/HeaderWrapper';
import { ContextMenuLink } from '../common/header/HeaderWrapper';

const SideNavigation = ({
  isMobile,
  isSideNavOpen,
  onSettingsClick,
  onLogoutClick,
  theme,
  activeLink,
  handleLinkClick,
  setIsSideNavOpen
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isSideNavOpen) {
      document.body.style.overflowY = 'scroll';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'static';
    }
  }, [isSideNavOpen]);

  const toggleSideNav = () => {
    setIsSideNavOpen(prev => !prev);
  };

  const dataAccessNavLinks = [
    {
      to: '/buckets',
      label: t('buckets'),
      icon: (
        <BucketIcon
          fill={
            activeLink.includes('/buckets')
              ? theme.themes.navActiveColor
              : theme.themes.navColor
          }
          extraStyle={{ marginRight: '15px' }}
        />
      ),
      onclick: () => {
        handleLinkClick();
      }
    },
    {
      to: '/usage',
      label: t('usage'),
      icon: (
        <UsageIcon
          fill={
            activeLink.includes('/usage')
              ? theme.themes.navActiveColor
              : theme.themes.navColor
          }
          extraStyle={{ marginRight: '15px' }}
        />
      ),
      onclick: () => {
        handleLinkClick();
      }
    }
  ];

  const accountNavLinks = [
    {
      label: t('settings'),
      onClick: () => onSettingsClick(),
      icon: (
        <SettingsIcon
          fill={
            activeLink.includes('/settings')
              ? theme.themes.navActiveColor
              : theme.themes.navColor
          }
          extraStyle={{ marginRight: '15px' }}
        />
      ),
      className: activeLink.includes('/settings') ? 'active' : ''
    },
    {
      label: t('logOut'),
      onClick: () => onLogoutClick(),
      icon: (
        <LogOutIcon
          fill={theme.themes.navColor}
          extraStyle={{ marginRight: '15px' }}
        />
      )
    }
  ];

  return (
    <SideNavWrapper>
      <SideNavHeader>
        <LogoWrapper isMobile={isMobile}>
          <LogoStyle
            src={theme.headerLogo}
            display={theme.headerLogo ? 'block' : 'none'}
          />
        </LogoWrapper>
        <LangMenuContainer>
          <LanguageSelector menuPortalTarget={null} />
          <MenuIconContainer onClick={toggleSideNav}>
            {isSideNavOpen ? (
              <CloseIcon fill={theme.themes.navColor} />
            ) : (
              <MenuIcon fill={theme.themes.navColor} />
            )}
          </MenuIconContainer>
        </LangMenuContainer>
      </SideNavHeader>
      {isSideNavOpen && (
        <SideNav>
          <Box>
            <SideNavSectionHeader>{t('dataAccess')}</SideNavSectionHeader>
            <LinkContainer>
              {dataAccessNavLinks.map(({ to, label, icon, onclick }) => (
                <NavbarLink
                  key={label}
                  isMobile={isMobile}
                  exact="true"
                  activeclassname="active"
                  to={to}
                  onClick={onclick}
                >
                  {icon}
                  {label}
                </NavbarLink>
              ))}
            </LinkContainer>
          </Box>
          <Box>
            <SideNavSectionHeader>{t('account')}</SideNavSectionHeader>
            <LinkContainer>
              {accountNavLinks.map(({ label, onClick, icon, className }) => (
                <ContextMenuLink
                  key={label}
                  onClick={onClick}
                  isMobile={isMobile}
                  className={className}
                >
                  {icon}
                  {label}
                </ContextMenuLink>
              ))}
            </LinkContainer>
          </Box>
        </SideNav>
      )}
    </SideNavWrapper>
  );
};

export default SideNavigation;

SideNavigation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSideNavOpen: PropTypes.bool.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  setIsSideNavOpen: PropTypes.func.isRequired,
  activeLink: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired
};

const SideNavWrapper = styled(Box)`
  width: inherit;
`;

const SideNavHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  min-height: 40px;
  padding: 10px 15px;
  padding-left: 5px;
`;

const SideNav = styled(Box)`
  height: 100vh;
  background-color: white;
  background-image: linear-gradient(
    ${({ theme }) => theme.themes?.headerBackground},
    ${({ theme }) => theme.themes?.headerBackground}
  );
  padding-block-start: 20px;
  & > div:not(:first-child) > p {
    margin-block-start: 20px;
  }
`;

const SideNavSectionHeader = styled(Typography)`
  color: ${props => props.theme.themes?.navColor};
  opacity: 0.7;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-inline-start: 30px !important;
`;

const LangMenuContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

const MenuIconContainer = styled(Box)`
  cursor: pointer;
  align-self: center;
`;

const LinkContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-block-start: 28px;
`;

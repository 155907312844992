import React from 'react';

export const Label = ({ filled, label, htmlFor, icon, width, ...props }) => {
  return (
    <label
      {...props}
      htmlFor={htmlFor}
      className={filled ? 'filledField' : ''}
      style={{
        width: width
      }}
    >
      {label}
      {icon && icon}
    </label>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import RangePicker from '../../range-picker';
import Icon from '../../icon/Icon';
import Box from '../../box/Box';
import Text from '../../text/Text';
import Title from '../../title/Title';
import { colors } from '../../../constant';
import LightTooltip from '../../tooltip/Tooltip';
import { mobile } from '../../../../constants/breakpoints';
import { TableHeaeder, TableHeaederElement } from '../helpers';

const TableHeader = props => {
  const {
    dateOnChange,
    dateValue,
    cleanable,
    display,
    searchable,
    exportOnClick,
    titleTable,
    title,
    tooltip,
    filters,
    disabledDate,
    searchComponent,
    multiFilter,
    lastSync
  } = props;

  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  return (
    <TableHeaeder>
      {titleTable && (
        <TableHeaederElement>
          <Title
            fontSize="18px"
            fontWeight="500"
            lineheight="normal"
            color={colors.black}
            titleTable={titleTable}
          >
            {title}
            {tooltip && (
              <LightTooltip
                maxWidth={230}
                title={tooltip}
                placement="bottom-start"
              >
                <span>
                  <Icon icon="info_icon" ml="5px" width="11px" />
                </span>
              </LightTooltip>
            )}
          </Title>
        </TableHeaederElement>
      )}

      <TableHeaederElement>
        {searchable && searchComponent}
        {filters && multiFilter}
      </TableHeaederElement>

      <TableHeaederElement>
        <RangePicker
          lastSync={lastSync}
          value={dateValue}
          onChange={date => dateOnChange(date)}
          cleanable={cleanable}
          display={display}
          disabledDate={disabledDate}
        />

        <Box
          border="1px solid #f1f1f1"
          borderradius="6px"
          p="10px 20px"
          display="flex"
          alignitems="center"
          ml={isMobile ? 1 : '20px'}
          pointer="true"
          onClick={exportOnClick}
        >
          <Icon icon="exportIcon" width="15px" mr="8px" mt="-2px"></Icon>
          <Text>{t('export')}</Text>
        </Box>
      </TableHeaederElement>
    </TableHeaeder>
  );
};

export default TableHeader;

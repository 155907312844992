import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './ccc-language-pack/locale/translationEN.json';
import translationPT from './ccc-language-pack/locale/translationPT.json';
import translationIT from './ccc-language-pack/locale/translationIT.json';
import translationDE from './ccc-language-pack/locale/translationDE.json';
import translationFR from './ccc-language-pack/locale/translationFR.json';

// eslint-disable-next-line no-restricted-globals
const language = localStorage.getItem('language');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      pt: {
        translation: translationPT
      },
      it: {
        translation: translationIT
      },
      de: {
        translation: translationDE
      },
      fr: {
        translation: translationFR
      }
    },
    lng: JSON.parse(language)?.value, // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false // react already safe from xss
    }
  });

export default i18n;

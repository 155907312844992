import styled from 'styled-components';
import { colors } from '../../../../constant';

export const AccountInformationContainer = styled.div`
  background-color: white;
  padding: 30px;
  margin-bottom: 30px;
`;

export const AccountInformationHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors?.softGray};
  margin-bottom: 20px;
`;

export const AccountInformationTitle = styled.span`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors?.black};
`;

export const AccountInformationDataContainer = styled.div`
  width: 100%;
  display: ${props => (props.isMobile ? 'flex-column' : 'flex')};
  gap: 30px;
  margin-top: 40px;
`;
export const AccountInformationLabelInputContainer = styled.div`
  display: flex;
  background-color: ${colors.softGray};
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border-radius: 4px;
  margin-top: 16px;
`;
export const AccountInformationInputs = styled.div`
  width: 100%;
  margin-top: ${props => (props.isMobile ? '30px' : 0)};
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../common/box/Box';
import Input from '../../../../../common/input/Input';
import Button from '../../../../../common/button/Button';
import { colors } from '../../../../../constant';

import {
  BoldDescription,
  ConfirmAndDescriptionContainer,
  ConfirmDescription,
  UnderlineDescription
} from './helpers';
export default function ConfirmResetKeys({ formik }) {
  const { t } = useTranslation();
  useEffect(() => {
    return () => formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmAndDescriptionContainer>
      <ConfirmDescription>
        <BoldDescription> {t('warningTitleCase')}:</BoldDescription>{' '}
        {t('resetKeysDescriptionStart')}{' '}
        <BoldDescription>
          {t('allCurrentExistingRootAccessKeys')}
        </BoldDescription>{' '}
        <UnderlineDescription>{t('deletedLower')}</UnderlineDescription>{' '}
        {t('resetKeysDescriptionEnd')}
      </ConfirmDescription>
      <ConfirmDescription>
        {t('typeToProceed')}{' '}
        <BoldDescription>&apos;{t('confirmUpper')}&apos;</BoldDescription>{' '}
        {t('toProceed')}
      </ConfirmDescription>
      <Box display={'flex'} alignitems={'center'} gap={'21px'}>
        <Input
          marginBottom="0"
          width="100%"
          fontSize="14px"
          name={'confirm'}
          formData={formik}
          value={formik?.values['confirm']}
          onChange={formik?.handleChange}
          margin={'0'}
        />
        <Button
          isLoading={formik.isSubmitting}
          onClick={formik?.handleSubmit}
          disabled={!formik.isValid || !formik.dirty}
          className={'delete'}
          fontWeight={500}
          color={colors.white}
          bordercolor="transparent"
          bgcolor={colors.red}
          primary
          type={'submit'}
          w="fit-content"
        >
          {t('confirm')}
        </Button>
      </Box>
    </ConfirmAndDescriptionContainer>
  );
}

ConfirmResetKeys.propTypes = {
  formik: PropTypes.object.isRequired
};

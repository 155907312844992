import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Icon from '../icon/Icon';
import { useStoreState } from 'easy-peasy';
import LightTooltip from '../tooltip/Tooltip';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { theme } = useStoreState(actions => actions.theme);
  const {
    width,
    value,
    fontWeight,
    iconName,
    iconMinWidth,
    color,
    customClassName,
    noTooltip = false
  } = props;
  const cellValue = useRef(null);
  const [showPopper, setShowPopper] = useState(false);

  return (
    <Box
      minWidth={width - 10}
      onMouseEnter={() => setShowPopper(isOverflown(cellValue.current))}
      onMouseLeave={() => setShowPopper(false)}
      className={customClassName}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        fontWeight,
        maxWidth: width - 4
      }}
    >
      {iconName && (
        <Icon minwidth={iconMinWidth} mr="10px" icon={iconName} fill="none" />
      )}
      <LightTooltip
        open={showPopper && !noTooltip}
        placement="bottom-start"
        PopperProps={{
          positionFixed: true,
          modifiers: {
            name: 'preventOverflow',
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window'
            }
          }
        }}
        title={showPopper ? value : ''}
      >
        <Box
          ref={cellValue}
          sx={{
            maxWidth: width,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: color ?? theme.themes?.colorPrimary
          }}
        >
          {value}
        </Box>
      </LightTooltip>
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  fontWeight: PropTypes.string,
  iconName: PropTypes.string,
  iconMinWidth: PropTypes.string,
  color: PropTypes.string,
  customClassName: PropTypes.string
};

// The renderCellExpand function remains the same as in your original code.

export function renderCellExpand({
  value,
  colDef,
  width,
  fontWeight,
  iconName,
  iconMinWidth,
  color,
  customClassName,
  extraElement = null,
  noTooltip = false
}) {
  const mainElementWidth =
    colDef?.computedWidth || width.colDef?.computedWidth || width;
  const extraElementWidth =
    extraElement?.colDef?.computedWidth || width.colDef?.computedWidth || width;
  return (
    <span style={{ maxWidth: mainElementWidth - 5 }}>
      <GridCellExpand
        value={value || ''}
        width={mainElementWidth}
        fontWeight={fontWeight}
        iconName={iconName}
        iconMinWidth={iconMinWidth}
        color={color}
        customClassName={customClassName}
        noTooltip={noTooltip}
      />
      {extraElement && (
        <GridCellExpand
          value={extraElement?.value || ''}
          width={extraElementWidth}
          fontWeight={extraElement?.fontWeight}
          iconName={extraElement?.iconName}
          iconMinWidth={extraElement?.iconMinWidth}
          color={extraElement?.color}
        />
      )}
    </span>
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.string,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,

  fontWeight: PropTypes.string,

  iconName: PropTypes.string,

  iconMinWidth: PropTypes.string,

  color: PropTypes.string,

  customClassName: PropTypes.string
};

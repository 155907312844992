export const popperProps = {
  disablePortal: true,
  popperOptions: {
    positionFixed: true,
    modifiers: {
      name: 'preventOverflow',
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window'
      }
    }
  }
};

export const lastTooltipPopperProps = {
  popperOptions: {
    positionFixed: true,
    modifiers: {
      name: 'preventOverflow',
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window'
      }
    }
  }
};

export const getFlagUrl = region => {
  switch (region) {
    case 'us-west-1':
      return 'US';
    case 'us-east-1':
      return 'US';
    case 'us-east-2':
      return 'US';
    case 'us-central-1':
      return 'US';
    case 'us-east-3':
      return 'US';
    case 'ca-central-1':
      return 'CA';
    case 'eu-west-1':
      return 'UK';
    case 'eu-west-3':
      return 'UK';
    case 'eu-west-2':
      return 'FR';
    case 'eu-central-1':
      return 'NL';
    case 'eu-central-2':
      return 'DE';
    case 'ap-northeast-1':
      return 'JP';
    case 'ap-northeast-2':
      return 'JP';
    case 'ap-southeast-2':
      return 'AU';
    case 'ap-southeast-1':
      return 'SG';
    case 'eu-south-1':
      return 'IT';
    default:
      return '';
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../../../constants/breakpoints';
import Box from '../../../../common/box/Box';
import Button from '../../../../common/button/Button';
import hasPermission from '../../../../../security/Permission';
import ConfigRenderer from './components/ConfigRenderer';
import NoOrganizationContainer from './components/NoOrganizationContainer';
import AddOrEditOrganizationModal from './components/AddOrEditOrganizationModal';
import { createOrUpdateConnection, getSSOConfig } from '../../../endpoints';
import { showToast } from '../../../../../hooks/show-toast';
import { LoaderWrap } from '../../../../common/loader/Loader';
import { getBase64 } from '../../../../../utils/formati-file.utils';
import { colors } from '../../../../constant';

import Roles from './components/Roles';
import SSOConfigHeader from './components/SSOConfigHeader';
import DeleteOrganizationModal from './components/DeleteOrganizationModal';
import { SSOConfigContainer } from './styles';
import { initialValues, validationSchema } from './helpers';

export default function SSOConfig() {
  const { t } = useTranslation();

  const deleteSSOPerm = hasPermission('oem_sso', 'delete');
  const editSSOPerm = hasPermission('oem_sso', 'connection');
  const [ssoConfigData, setssoConfigData] = useState(null);
  const [showCreateOrg, setShowCreateOrg] = useState(false);
  const [showDeleteSSOModal, setShowDeleteSSOModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const getData = (isOrgEdited = false) => {
    setIsLoading(true);
    getSSOConfig().then(([res]) => {
      if (!isOrgEdited) {
        formik.resetForm();
        setssoConfigData({
          organizationName: res?.data?.organisation?.name,
          connectionType: res?.data?.connection?.strategy || 'OIDC',
          channelType: res?.data?.connection?.channelType || 'front_channel',
          discoveryEndpoint: res?.data?.connection?.discoveryUrl,
          clientId: res?.data?.connection?.clientId,
          clientSecret:
            res?.data?.connection?.channelType === 'back_channel'
              ? res?.data?.connection?.clientSecret
              : '',
          enabled: res?.data?.enabled,
          signInUrl: res?.data?.connection?.signInEndpoint,
          signOutUrl: res?.data?.connection?.signOutEndpoint,
          signingCert: res?.data?.connection?.signingCert || ''
        });
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const formik = {
    ...useFormik({
      validationSchema: validationSchema,
      initialValues: ssoConfigData || initialValues,
      enableReinitialize: true,
      onSubmit: (fieldValues, actions) => handleSubmit(fieldValues, actions)
    })
  };

  const handleSubmit = async () => {
    setButtonLoading(true);

    const {
      connectionType,
      discoveryEndpoint,
      clientId,
      clientSecret,
      channelType,
      signInUrl,
      signingCert,
      signOutUrl
      // eslint-disable-next-line no-unsafe-optional-chaining
    } = formik?.values;
    let dataVariable = null;

    if (connectionType === 'SAMLP') {
      try {
        if (typeof signingCert === 'string') {
          dataVariable = signingCert;
        } else {
          dataVariable = await getBase64(signingCert);
        }
      } catch (error) {
        // silent err
      }
    }

    const data = {
      strategy: connectionType,
      ...(connectionType === 'OIDC' && {
        discoveryUrl: discoveryEndpoint,
        channelType,
        clientId,
        clientSecret
      }),
      ...(connectionType === 'SAMLP' && {
        signInEndpoint: signInUrl,
        signOutEndpoint: signOutUrl,
        signingCert: dataVariable
      })
    };

    createOrUpdateConnection(data).then(([res, err]) => {
      if (res?.success) {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        getData();
      }
      if (err) {
        const res = err?.response?.data;
        showToast(
          {
            message: res?.code || res?.Code || err?.message,
            success: err?.success
          },
          err?.success ? 'success' : 'error'
        );
      }
      setButtonLoading(false);
    });
  };

  return (
    <>
      {!ssoConfigData?.organizationName?.length && !isLoading ? (
        <NoOrganizationContainer onClick={() => setShowCreateOrg(true)} />
      ) : (
        <SSOConfigContainer>
          {isLoading ? (
            <LoaderWrap height="300px" display />
          ) : (
            <>
              <SSOConfigHeader
                toggleSuccess={() =>
                  setssoConfigData({
                    ...ssoConfigData,
                    enabled: !ssoConfigData?.enabled
                  })
                }
                isToggled={ssoConfigData?.enabled}
                orgName={
                  formik?.values?.organizationName ||
                  ssoConfigData?.organizationName
                }
                onClick={() => setShowCreateOrg(true)}
              />
              {ssoConfigData?.enabled && (
                <ConfigRenderer ssoConfigData={ssoConfigData} formik={formik} />
              )}
              {ssoConfigData?.enabled && (
                <Box
                  w="100%"
                  display="flex"
                  justifycontent="flex-end"
                  mt="20px"
                  gap="7px"
                >
                  <Button
                    disabled={!deleteSSOPerm}
                    className={'delete'}
                    color={colors.white}
                    bgcolor={colors.red}
                    isMobile={isMobile}
                    w="275px"
                    minHeight="40px"
                    onClick={() => setShowDeleteSSOModal(true)}
                  >
                    {t('deleteSSOConfiguration')}
                  </Button>
                  <Button
                    disabled={!formik.dirty || !formik.isValid || !editSSOPerm}
                    primary
                    w="205px"
                    isMobile={isMobile}
                    isLoading={buttonLoading}
                    minHeight="40px"
                    onClick={() => handleSubmit()}
                  >
                    {t('saveConnection')}
                  </Button>
                </Box>
              )}
              {ssoConfigData?.enabled && <Roles />}
            </>
          )}
        </SSOConfigContainer>
      )}
      {showCreateOrg && (
        <AddOrEditOrganizationModal
          existingOrganizationName={
            formik?.values?.organizationName || ssoConfigData?.organizationName
          }
          isEditing={ssoConfigData?.organizationName?.length > 0}
          close={() => setShowCreateOrg(false)}
          onSubmitFinish={() =>
            getData(Boolean(ssoConfigData?.organizationName?.length > 0))
          }
          show
          formData={formik}
        />
      )}
      <DeleteOrganizationModal
        open={showDeleteSSOModal}
        close={() => setShowDeleteSSOModal(false)}
        onSuccess={() => getData()}
      />
    </>
  );
}

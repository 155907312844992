import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../properties';
import { ReactComponent as globe } from '../../../assets/icons/globe.svg';
import { ReactComponent as bucket } from '../../../assets/icons/bucket_icon.svg';
import { ReactComponent as folder } from '../../../assets/icons/folder.svg';
import { ReactComponent as file } from '../../../assets/icons/file.svg';
import { ReactComponent as versionedFile } from '../../../assets/icons/versioned-file.svg';
import { ReactComponent as deleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as x } from '../../../assets/icons/x.svg';
import { ReactComponent as edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as download } from '../../../assets/icons/download.svg';
import { ReactComponent as copy } from '../../../assets/icons/copy.svg';
import { ReactComponent as deleteOutlined } from '../../../assets/icons/delete_icon.svg';
import { ReactComponent as upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as verify } from '../../../assets/icons/verify.svg';
import { ReactComponent as settings } from '../../../assets/icons/settings.svg';
import { ReactComponent as logout } from '../../../assets/icons/logout.svg';
import { ReactComponent as checkedIcon } from '../../../assets/icons/check_icon.svg';
import { ReactComponent as view } from '../../../assets/icons/view.svg';
import { ReactComponent as success } from '../../../assets/icons/success.svg';
import { ReactComponent as error } from '../../../assets/icons/error.svg';
import { ReactComponent as warning } from '../../../assets/icons/warning.svg';
import { ReactComponent as info } from '../../../assets/icons/info.svg';
import { ReactComponent as bucketIcon } from '../../../assets/icons/bucket.svg';
import { ReactComponent as lock } from '../../../assets/icons/lock.svg';
import { ReactComponent as cloud } from '../../../assets/icons/cloud-loader.svg';
import { ReactComponent as deleteFilled } from '../../../assets/icons/delete_filled.svg';
import { ReactComponent as arrow_left } from '../../../assets/icons/arrow_left.svg';
import { ReactComponent as arrow_right } from '../../../assets/icons/arrow_right.svg';
import { ReactComponent as print } from '../../../assets/icons/print.svg';
import { ReactComponent as close_eye } from '../../../assets/icons/closeeye.svg';
import { ReactComponent as generate_access_key } from '../../../assets/icons/generate_access_key.svg';
import { ReactComponent as reset_access_keys } from '../../../assets/icons/reset_access_keys.svg';
import { ReactComponent as green_checkmark } from '../../../assets/icons/green-checkmark.svg';
import { ReactComponent as arrow_down } from '../../../assets/icons/arrow_down.svg';
import { ReactComponent as info_icon } from '../../../assets/icons/info_icon.svg';
import { ReactComponent as exportIcon } from '../../../assets/icons/export_icon.svg';
import { ReactComponent as arrow_up } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as arrow_down_sec } from '../../../assets/icons/arrow_down_sec.svg';
import { ReactComponent as clearFilter } from '../../../assets/icons/clear_filter.svg';
import { ReactComponent as mfa_enabled } from '../../../assets/icons/mfa_enabled.svg';
import { ReactComponent as clock } from '../../../assets/icons/clock.svg';
import { ReactComponent as deactivate_member } from '../../../assets/icons/deactivate_member.svg';
import { ReactComponent as view_member } from '../../../assets/icons/view_member.svg';
import { ReactComponent as locked_clock } from '../../../assets/icons/locked_clock.svg';
import { ReactComponent as activate_member } from '../../../assets/icons/activate_member.svg';
import { ReactComponent as tooltip_darker } from '../../../assets/icons/tooltip_darker.svg';
import { ReactComponent as menu } from '../../../assets/icons/menu.svg';
import { ReactComponent as close } from '../../../assets/icons/close.svg';

export const availableIcons = {
  globe,
  bucket,
  folder,
  file,
  deleteIcon,
  x,
  edit,
  download,
  copy,
  deleteOutlined,
  upload,
  verify,
  settings,
  logout,
  checkedIcon,
  view,
  success,
  error,
  warning,
  info,
  bucketIcon,
  lock,
  cloud,
  deleteFilled,
  arrow_left,
  arrow_right,
  print,
  close_eye,
  generate_access_key,
  reset_access_keys,
  versionedFile,
  green_checkmark,
  arrow_down,
  exportIcon,
  arrow_up,
  info_icon,
  arrow_down_sec,
  clearFilter,
  mfa_enabled,
  clock,
  deactivate_member,
  view_member,
  locked_clock,
  activate_member,
  tooltip_darker,
  menu,
  close
};

export const iconNames = Object.keys(availableIcons);

const Icon = styled(
  ({
    icon,
    width,
    minwidth,
    strokeWidth,
    color,
    className,
    onClick,
    pathFill,
    transform
  }) => {
    const Icon = availableIcons[icon];

    if (!Icon) {
      throw `Missing icon named: "${icon}", please check your version of ui-components`;
    }

    return (
      <Icon
        onClick={onClick}
        strokeWidth={strokeWidth}
        minwidth={minwidth}
        width={width}
        color={color}
        className={className}
      />
    );
  }
)`
  z-index: 4;
  cursor: pointer;

  ${props =>
    props?.pathFill &&
    `
    path{
        fill: ${props.pathFill}
    }
 `}
  ${color}
  ${({
    width,
    minwidth,
    height,
    strokeWidth,
    fill,
    mr,
    mb,
    ml,
    position,
    right,
    top,
    left,
    display,
    transform,
    cursor
  }) =>
    width &&
    `
      stroke-width: ${strokeWidth ?? ''};
      width: ${width ?? 'initial'};
      min-width: ${minwidth ?? 'inherit'};
      height: ${height ?? 'initial'};
      fill: ${fill ?? 'initial'};
      margin-right: ${mr ?? 'initial'};
      margin-bottom: ${mb ?? 'initial'};
      margin-left: ${ml ?? 'initial'};
      position: ${position ?? 'initial'};
      right: ${right ?? 'initial'};
      left: ${left ?? 'initial'};
      top: ${top ?? 'initial'};
      display: ${display ?? 'initial'};
      transform: ${transform ?? ''};
      cursor: ${cursor || 'pointer'};
    `}
`;

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(availableIcons)).isRequired,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

Icon.defaultProps = {
  width: '24px',
  height: 'auto',
  color: 'inherit',
  fill: 'inherit'
};

export default Icon;

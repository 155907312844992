import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors } from '../../constant';

export const HeaderWrapper = styled.nav(
  ({ theme }) => `
    background-color: ${theme.themes?.headerBackground};
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1299;
  `
);

export const HeaderWrapperInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props => props.size};
  padding: ${props => props.padding};
  width: ${props => props.width};
  gap: 5px;
`;

export const HeaderWrapperInnerStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const NavbarLink = styled(NavLink)(
  ({ theme, isMobile }) => `
    color: ${theme.themes?.navColor};
    font-size: ${isMobile ? '16px' : '14px'};
    font-weight: 500;
    padding: ${isMobile && '10px 28px'};
    display: ${isMobile && 'flex'};
    align-items: ${isMobile && 'center'};
  
    &:not(:last-child) {
      margin-right: ${isMobile ? '0px' : '30px'};
    }
    &:after {
      bottom: ${isMobile ? '0' : '-20px !important'};
    }
    &.active {
      border-left: ${
        isMobile ? `4px solid ${theme.themes?.navActiveColor}` : '0px'
      };
      padding-left: ${isMobile ? '24px' : '0px'};

      &::after {
        display: ${isMobile ? 'none !important' : 'block'};
      }
    }
  `
);

export const LogoWrapper = styled.div`
  max-width: 125px;
  max-height: 50px;
  margin-right: ${props => (props.isMobile ? '12px' : '40px')};
  margin-left: ${props => (props.isMobile ? '-25px' : '')};
`;

export const LogoStyle = styled.img`
  max-width: 240px;
  max-height: 35px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: ${props => props.display ?? ''};
`;

export const QuestionIconStyle = styled.img`
  cursor: pointer;
`;

export const HeaderProfile = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const HeaderProfileName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.themes?.navColor};
  margin: ${props => (props.isMobile ? '0 10px' : '0 20px')};
`;

export const HeaderProfileImg = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
`;

export const ContextMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 10px;
  min-width: 140px;
  padding: 10px 5px;
  background-color: ${colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
  display: ${props => props.display ?? 'flex'};
  flex-direction: column;
  border-radius: 4px;
`;

export const ContextMenuLink = styled.a`
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '14px')};
  font-weight: ${({ isMobile }) => (isMobile ? 500 : 'normal')};
  color: ${({ theme, isMobile }) =>
    isMobile ? theme.themes?.navColor : colors.bodyColor} !important;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  padding: ${({ isMobile }) => (isMobile ? '10px 28px' : '12px 10px')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ isMobile }) => !isMobile && colors.lightGray};
    color: ${({ isMobile }) => !isMobile && colors.bodyColor} !important;
  }

  &.active {
    border-left: ${({ isMobile, theme }) =>
      isMobile ? `4px solid ${theme.themes?.navActiveColor}` : '0px'};
    padding-left: ${({ isMobile }) => (isMobile ? '24px' : '0px')};

    &::after {
      display: ${({ isMobile }) => (isMobile ? 'none !important' : 'block')};
    }
  }
`;

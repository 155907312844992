import styled from 'styled-components';
import { fontWeight, textalign } from '../../properties';
import { colors } from '../../constant';

const Label = styled.label`
  margin: 0;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  color: ${props => (props.color ? props.color : colors.black)};
  text-decoration: ${props => props.textDecoration && props.textDecoration};
  cursor: ${props => props.cursor && props.cursor};
  word-break: break-all;
  ${fontWeight}
  ${textalign};
`;

export default Label;

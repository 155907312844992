/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '../../common/box/Box';
import Title from '../../common/title/Title';
import Line from '../../common/line/Line';
import Input, { ErrorMessage } from '../../common/input/Input';
import Icon from '../../common/icon/Icon';
import Button from '../../common/button/Button';
import hasPermission from '../../../security/Permission';
import ChangePasswordNotAvailable from '../../common/change-password-not-available';
import { colors } from '../../constant';
import { LoaderWrap } from '../../common/loader/Loader';
import { changeMemberPassword } from '../../auth/endpoints';
import { showToast } from '../../../hooks/show-toast';

export default function MemberDetailsChangePassword({ id, isSSO }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({ mode: 'onChange' });
  const oldPassword = '12345678';
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const canChangeMemberPasswordPerm = hasPermission(
    'oem_members',
    'change_password'
  );
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onNewPasswordChange = e => {
    setNewPassword(e.target.value);
    setPasswordVerified(
      confirmPassword.length > 0 && e.target.value === confirmPassword
    );
  };

  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    setPasswordVerified(
      newPassword.length > 0 && e.target.value === newPassword
    );
  };

  const handleFormSubmit = () => {
    setDisplayLoader(true);
    setIsButtonLoading(true);
    if (passwordVerified) {
      setDisplayLoader(false);
      changeMemberPassword(newPassword, id).then(([res]) => {
        if (res.success) {
          setNewPassword('');
          setConfirmPassword('');
          setPasswordVerified(false);
        }
        setDisplayLoader(false);
        if (res?.success) reset();
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
        setIsButtonLoading(false);
      });
    }
  };

  return (
    <>
      <Box bgcolor={colors.white} p="30px" mt="30px">
        <Box display="flex" alignitems="center" justifycontent="space-between">
          <Title fontSize="18px" fontWeight="500" color={colors.black}>
            {t('changePassword')}
          </Title>
        </Box>

        <Line m="20px 0 40px" />
        {isSSO ? (
          <ChangePasswordNotAvailable />
        ) : (
          <form
            onSubmit={handleSubmit(
              canChangeMemberPasswordPerm ? handleFormSubmit : null
            )}
          >
            <div
              className={`input-wrapper ${
                isSSO ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="oldPassword"
                type="password"
                marginBottom="30px"
                value="******"
                readOnly
              />

              <label
                htmlFor="oldPassword"
                className={
                  oldPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('oldPassword')}*
              </label>
              {errors.oldPassword?.type === 'required' && (
                <ErrorMessage>{t('oldPasswordRequired')}</ErrorMessage>
              )}
            </div>

            <div
              className={`input-wrapper ${
                isSSO ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="newPassword"
                type="password"
                marginBottom="30px"
                disabled={isSSO}
                className={errors.newPassword ? 'not-valid' : ''}
                {...register('newPassword', {
                  onChange: onNewPasswordChange,
                  required: true,
                  minLength: 5,
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[\" \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\"])(?=.*[a-zA-Z]).{6,}/,
                    message: t('passwordPattern')
                  }
                })}
              />

              <label
                htmlFor="newPassword"
                className={
                  newPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('newPassword')}*
              </label>
              {errors.newPassword?.type === 'minLength' && (
                <ErrorMessage>{t('newPasswordPattern')}</ErrorMessage>
              )}
              {errors.newPassword?.type === 'pattern' && (
                <ErrorMessage>{t('newPasswordPattern')}</ErrorMessage>
              )}
              {errors.newPassword?.type === 'required' && (
                <ErrorMessage>{t('newPasswordRequired')}</ErrorMessage>
              )}
            </div>

            <div
              className={`input-wrapper ${
                isSSO ? 'input-wrapper-disabled' : ''
              }`}
            >
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                name="confirmPassword"
                type="password"
                marginBottom="0"
                padding="0 35px 0 10px"
                disabled={isSSO}
                className={
                  !passwordVerified && confirmPassword.length > 0
                    ? 'not-valid'
                    : ''
                }
                {...register('confirmPassword', {
                  onChange: onConfirmPasswordChange,
                  required: true
                })}
              />

              <label
                htmlFor="confirmPassword"
                className={
                  confirmPassword.length > 0 ? 'filledField' : 'input-label'
                }
              >
                {t('confirmPassword')}*
              </label>
              <Icon
                icon="verify"
                position="absolute"
                right="10px"
                top="12px"
                display={passwordVerified && isValid ? 'block' : 'none'}
              />
              {!passwordVerified && confirmPassword.length > 0 && (
                <ErrorMessage className="error-message--nospace">
                  {t('passwordsMustMatch')}
                </ErrorMessage>
              )}
            </div>

            {canChangeMemberPasswordPerm && (
              <Box end="true" mt="30px">
                <Button
                  isLoading={isButtonLoading}
                  disabled={!passwordVerified || !isValid}
                  type={'submit'}
                  w="fit-content"
                  primary
                >
                  {t('update')}
                </Button>
              </Box>
            )}
          </form>
        )}
        <LoaderWrap display={displayLoader} />
      </Box>
    </>
  );
}

MemberDetailsChangePassword.propTypes = {
  id: PropTypes.string.isRequired,
  isSSO: PropTypes.bool.isRequired
};

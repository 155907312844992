import styled from 'styled-components';
import { colors } from '../../constant';

// Input //
const Input = styled.input.attrs(props => ({
  // or we can define dynamic ones
  size: props.size || '0'
}))`
  font-size: 1em;
  border: 1px solid ${props => props.borderColor || '#f1f1f1'};
  border-radius: 4px;
  color: ${props => props.inputColor || '#788089'};
  background: ${props => props.bgcolor || 'transparent'};

  /* here we use the dynamically computed prop */
  margin: ${props => props.size || 0};
  padding: ${props => props.padding || '0 10px'};
  width: ${props => props.width};
  font-size: ${props => props.fontSize};
  min-height: ${props => props.minHeight || '40px'};
  margin-bottom: ${props => props.marginBottom};

  &:not(:last-child) {
    margin-bottom: ${props => props.marginBottom || '30px'};
  }

  &.not-valid {
    border-color: red;
  }

  &[readonly] {
    background-color: ${colors.softGray};
  }

  &.create-member-new {
    &[readonly] {
      background-color: transparent;
    }

    &--url {
      font-weight: 600;
      color: #466fb6;
    }
  }
`;

export default Input;

export const ErrorMessage = styled.div`
  display: flex;
  word-break: break-word;
  font-size: 11px;
  color: ${colors.red};
  position: absolute;
  top: ${props => props?.top || 'calc(100% - 29px)'};
  margin-top: ${props => props.marginTop || '0'};
  line-height: 12px;

  &.error-message--wrap {
    top: calc(100% - 19px);
  }

  &.error-message--nospace {
    top: 100%;
  }
`;

export const BucketNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom};
`;

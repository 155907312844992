import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../../constants/breakpoints';

import { colors } from '../../../constant';
import Box from '../../../common/box/Box';
import Text from '../../../common/text/Text';

import { Logo } from '../styles';

const LoginHeader = ({ theme }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  return (
    <Box
      display="flex"
      direction="column"
      alignitems="center"
      justifycontent="center"
    >
      <Logo src={theme.logo} alt="" isMobile={isMobile} />
      <Text
        fontSize="18px"
        fontWeight="500"
        mb="20px"
        wordbreak="break-word"
        textalign="center"
        color={colors.black}
      >
        {theme?.loginTitle}
      </Text>
    </Box>
  );
};

export default LoginHeader;

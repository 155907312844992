import React from 'react';
import {
  AccessKeyActionContainer,
  AccessKeyActionTitle,
  AccessKeyActionWrapper,
  IconContainer
} from './styles';

import Icon from '../../../../common/icon/Icon';

const AccessKeyAction = ({
  onClick,
  rotate,
  mainIcon,
  title,
  collapse = null,
  disabled,
  danger,
  width
}) => {
  return (
    <AccessKeyActionWrapper
      danger={danger}
      isDropdown={collapse}
      disabled={disabled}
      wrapperWidth={width}
    >
      <AccessKeyActionContainer
        danger={danger}
        onClick={onClick}
        isDropdown={collapse}
        disabled={disabled}
      >
        <Icon pathFill={disabled ? '#D9DFE6' : null} icon={mainIcon} />
        <AccessKeyActionTitle danger={danger} disabled={disabled}>
          {title}
        </AccessKeyActionTitle>
        {collapse && (
          <IconContainer rotate={rotate}>
            <Icon icon={collapse} />
          </IconContainer>
        )}
      </AccessKeyActionContainer>
    </AccessKeyActionWrapper>
  );
};

export default AccessKeyAction;

/* eslint-disable no-restricted-globals */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import { showToast } from '../../../hooks/show-toast';
import SSOIcon from '../../../assets/images/SSOIcon';
import hasPermission from '../../../security/Permission';
import { allColumns } from '../../../utils/columns';
import { mobile } from '../../../constants/breakpoints';

import { renderCellExpand } from '../../common/table/TableCellExpand';
import { LoaderWrap } from '../../common/loader/Loader';
import Box from '../../common/box/Box';
import Icon from '../../common/icon/Icon';
import Text from '../../common/text/Text';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import { colors } from '../../constant';
import { ActionMenuItem, Table } from '../../common/table/Table';
import { CustomPagination } from '../../common/pagination/Pagination';
import DeleteModal from '../../common/modal/DeleteModal';
import MfaModalDisabledMember from '../profile/multiFactorAuth/MfaModalDisabledMember';
import {
  deactivateMember,
  deleteMemberUser,
  getAllMembers,
  reinstateMember
} from '../endpoints';

import { CreateMemberNew } from './CreateMemeberNew';
import ToggleMemberStatus from './components/ToggleMemberStatus';
import ReinstateMemberModal from './components/ReinstateMemberModal';
import { CreateMembers } from './CreateMembers';
import {
  BorderBottom,
  MemberRenderer,
  RenderMFA,
  localizedTextsMap,
  renderStatus,
  tableSx
} from './helpers';

export function Members() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useStoreState(state => state.theme);
  const token = localStorage.getItem('Authorization');
  const canReinstateMember = hasPermission('oem_members', 'reinstate');
  const canDeactivateMember = hasPermission('oem_members', 'deactivate');
  const [buttonLoader, setButtonLoader] = useState(null);
  const canReadMembersPerm = hasPermission('oem_members', 'read_all');
  const canViewMemberPerm = hasPermission('oem_members', 'read');
  const canCreateMemberPerm = hasPermission('oem_members', 'create');
  const canDeleteMemberPerm = hasPermission('oem_members', 'delete');
  const canUpdateMemberMfaPerm = hasPermission('oem_members', 'update_mfa');
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [response, setResponse] = useState(null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [showDeleteMembers, setShowDeleteMembers] = React.useState(false);
  const [memberToDelete, setMemberToDelete] = React.useState('');
  const [showCreateMembers, setShowCreateMembers] = React.useState(false);
  const [showCreateMemberNew, setShowCreateMemberNew] = React.useState(false);
  const [newMemberData, setNewMemberData] = React.useState({});
  const [showMfaDisabledModal, setShowMfaDisabledModal] = React.useState(false);
  const [memberToDisabledMfa, setMemberToDisabledMfa] = React.useState('');
  const [reinstateMemberModal, setReinstateMemberModal] = useState(false);
  const [toggleMemberModal, setToggleMemberModal] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [isActivatingMember, setIsActivatingMember] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const containerRef = useRef(null);

  const fetchMembers = () => {
    setDisplayLoader(true);

    getAllMembers().then(([res]) => {
      if (res.success) {
        const data = res.data.map(item => {
          return {
            ...item,
            roles: t(item.roles)
          };
        });
        setResponse(data);
      } else if (res?.code || res?.Code !== 'ErrCcc0045')
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      window.scrollTo(0, 0);
      setDisplayLoader(false);
      setUpdateDisabled(false);
    });
  };

  useEffect(() => {
    fetchMembers();
    return () => setActionId(null);
  }, []);

  const handleClickEvent = (params, event) => {
    event.defaultMuiPrevented = true;

    if (params.id && canViewMemberPerm) {
      navigate('../../settings/members/'.concat(params.id));
    }
  };
  /**
   * Create Member Key.
   */
  const onCreateMembersClick = useCallback(
    () => () => {
      setShowCreateMembers(true);
    },
    []
  );

  const onDeleteClick = useCallback(
    id => () => {
      setShowDeleteMembers(true);
      setMemberToDelete(id);
    },
    []
  );

  const onDisableMFAClick = useCallback(
    id => () => {
      setMemberToDisabledMfa(id);
      setShowMfaDisabledModal(true);
    },
    []
  );

  useEffect(() => {}, [memberToDisabledMfa]);

  const handleCreateMember = data => {
    showToast(
      { message: data?.message, success: data?.success },
      data?.success ? 'success' : 'error'
    );
    fetchMembers();
    setNewMemberData({
      url: data?.url,
      email: data?.email,
      password: data?.password
    });
    setDisplayLoader(false);
    setShowCreateMemberNew(true);
  };

  const closeMemberView = () => {
    setShowCreateMemberNew(false);
    setNewMemberData({});
  };

  const closeDisabledMfaModal = data => {
    if (data && data.message) {
      showToast(
        { message: data?.message, success: data?.success },
        data?.success ? 'success' : 'error'
      );
    }
    setShowMfaDisabledModal(false);
    setMemberToDisabledMfa('');
    fetchMembers();
  };

  const handleDeleteMember = () => {
    setButtonLoader('delete');
    deleteMemberUser(memberToDelete).then(([res]) => {
      // setDisplayLoader(false);
      if (res.success) {
        setShowDeleteMembers(false);
        fetchMembers();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
      setButtonLoader(null);
    });
  };
  const toggleMemberHandler = () => {
    setButtonLoader('deactivate');
    deactivateMember(actionId).then(([res]) => {
      if (res.success) {
        fetchMembers();
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setToggleMemberModal(false);
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
      setIsActivatingMember(false);
      setButtonLoader(null);
    });
  };
  const reinstateMemberHandler = () => {
    setButtonLoader('reinstate');
    reinstateMember(actionId).then(([res]) => {
      if (res.success) {
        fetchMembers();
        setReinstateMemberModal(false);
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setToggleMemberModal(false);
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
      setButtonLoader(null);
    });
  };

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('member'),
        flex: 4,
        renderCell: params =>
          renderCellExpand({
            value: params.row.name,
            width: 225,
            fontWeight: '500',
            extraElement: {
              color: '#525E6B',
              fontSize: '12px',
              value: params?.row?.email,
              width: 225
            }
          })
      },
      {
        field: 'state',
        headerName: t('status'),
        flex: 2.4,
        renderCell: ({ row }) => renderStatus(row?.state)
      },
      {
        field: 'roles',
        headerName: t('role'),
        renderHeader: () => MemberRenderer(),
        flex: 2.3,
        minWidth: isMobile && 140,
        headerClassName: 'member__header'
      },
      {
        field: 'sso',
        headerName: t('sso'),
        renderCell: ({ row }) => (
          <> {row?.sso && <SSOIcon fill={theme.themes?.colorPrimary} />}</>
        ),
        headerClassName: 'mfa__header'
      },
      {
        field: 'mfa',
        headerName: t('mfa'),
        flex: 1.1,
        renderCell: RenderMFA,
        headerClassName: 'mfa__header'
      },
      {
        field: t('actions'),
        type: 'actions',
        flex: 1,
        minWidth: 40,
        cellClassName: 'right-side members',
        headerClassName: 'actions__header',
        getActions: params => [
          <ActionMenuItem
            key="viewMember"
            className={'menu-item'}
            label={t('viewMember')}
            onClick={() => navigate('/settings/members/'.concat(params.id))}
            showInMenu={canViewMemberPerm}
            icon={
              canViewMemberPerm ? (
                <Icon height="19px" icon="view_member" color={colors.gray} />
              ) : (
                <></>
              )
            }
          />,
          <ActionMenuItem
            key="deactivateMember"
            className={'menu-item'}
            label={t('deactivateMember')}
            showInMenu={
              params?.row?.state !== 'DEACTIVATED' &&
              canDeactivateMember &&
              !params.row.sso
            }
            onClick={() => {
              setActionId(params.id);
              setToggleMemberModal(true);
            }}
            icon={
              <Icon
                height="19px"
                icon="deactivate_member"
                color={colors.gray}
              />
            }
          />,
          <ActionMenuItem
            key="activateMember"
            className={'menu-item'}
            label={t('activateMember')}
            showInMenu={
              !params.row.sso &&
              params?.row?.state === 'DEACTIVATED' &&
              canDeactivateMember
            }
            onClick={() => {
              setActionId(params.id);
              setToggleMemberModal(true);
              setIsActivatingMember(true);
            }}
            icon={
              <Icon height="19px" icon="activate_member" color={colors.gray} />
            }
          />,
          <ActionMenuItem
            key="disableMFA"
            className={'menu-item'}
            label={t('disableMFA')}
            showInMenu={
              !params.row.sso &&
              canUpdateMemberMfaPerm &&
              params.row.mfa === 'Enabled'
            }
            disabled={params.row.mfa === 'Disabled'}
            onClick={
              params.row.mfa === 'Enabled' ? onDisableMFAClick(params.id) : null
            }
            icon={
              canUpdateMemberMfaPerm && params.row.mfa === 'Enabled' ? (
                <Icon height="19px" icon="lock" color={colors.gray} />
              ) : (
                <></>
              )
            }
          />,
          <ActionMenuItem
            key="reinstateMember"
            className={'menu-item'}
            label={t('reinstateMember')}
            showInMenu={
              !params.row.sso &&
              (params?.row?.state === 'LOCKED' ||
                params?.row?.state === 'SUSPENDED') &&
              canReinstateMember
            }
            onClick={() => {
              setActionId(params?.id);
              setReinstateMemberModal(true);
            }}
            icon={<Icon height="19px" icon="clock" color={colors.gray} />}
          />,
          <ActionMenuItem
            key="deleteMember"
            width={205}
            label={t('deleteMember')}
            onClick={canDeleteMemberPerm ? onDeleteClick(params.id) : null}
            showInMenu={!params.row.sso && canDeleteMemberPerm}
            sx={{ color: colors.red }}
            icon={
              canDeleteMemberPerm ? (
                <Icon height="19px" icon="deleteFilled" color={colors.red} />
              ) : (
                <></>
              )
            }
            className={'delete_action'}
          />
        ]
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div style={{ width: '100%' }}>
        <Box
          relative="true"
          p="30px"
          bgcolor={colors.white}
          data-react-id={canReadMembersPerm}
        >
          <Box
            display="flex"
            alignitems="center"
            justifycontent="space-between"
          >
            <Title fontWeight="500" fontSize="18px" color="#0B293A">
              {t('members')}
            </Title>
            {canCreateMemberPerm && (
              <Button
                primary
                minHeight="40px"
                onClick={onCreateMembersClick()}
                disabled={updateDisabled}
              >
                {t('createMember')}
              </Button>
            )}
          </Box>

          <BorderBottom />

          <Box
            relative="true"
            overflowX={`${isMobile && 'scroll'}`}
            ref={containerRef}
          >
            {response ? (
              response.length > 0 ? (
                <div style={{ width: '100%' }}>
                  <Table
                    hideActionIcons
                    autoHeight
                    rows={response}
                    rowHeight={57}
                    columns={allColumns(columns, response || [])}
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    getRowId={row => row.id}
                    onRowClick={handleClickEvent}
                    components={{
                      Pagination: CustomPagination,
                      NoResultsOverlay: () => (
                        <Text
                          fontSize="14px"
                          mt="10px"
                          color={colors.bodyColor}
                        >
                          {t('noData')}
                        </Text>
                      )
                    }}
                    sx={tableSx}
                    localeText={localizedTextsMap}
                    isMobile={isMobile}
                    containerRef={containerRef?.current?.clientWidth}
                  />
                </div>
              ) : (
                <Text fontSize="14px" color={colors.bodyColor}>
                  {t('noData')}
                </Text>
              )
            ) : (
              <Box p="20px 0">
                <LoaderWrap display={displayLoader} />
              </Box>
            )}
          </Box>
          <CreateMembers
            open={showCreateMembers}
            close={() => setShowCreateMembers(false)}
            onSubmit={e => handleCreateMember(e)}
          />
          <CreateMemberNew
            open={showCreateMemberNew}
            close={closeMemberView}
            data={newMemberData}
          />
          <DeleteModal
            open={showDeleteMembers}
            close={() => setShowDeleteMembers(false)}
            onSubmit={e => handleDeleteMember(e)}
            name={t('member')}
            deleteText={t('deleteMemberText')}
            text={t('deleteUpperCase')}
            isLoading={buttonLoader === 'delete'}
          />
          <MfaModalDisabledMember
            open={showMfaDisabledModal}
            close={closeDisabledMfaModal}
            text={t('disableUpper')}
            id={memberToDisabledMfa}
          />
          <ReinstateMemberModal
            isLoading={buttonLoader === 'reinstate'}
            handleSubmit={() => reinstateMemberHandler(actionId)}
            open={reinstateMemberModal}
            close={() => setReinstateMemberModal(false)}
          />
          <ToggleMemberStatus
            isLoading={
              buttonLoader === 'deactivate' || buttonLoader === 'activate'
            }
            activate={isActivatingMember}
            handleSubmit={() => toggleMemberHandler(actionId)}
            open={toggleMemberModal}
            close={() => {
              setToggleMemberModal(false);
              setIsActivatingMember(false);
            }}
          />
        </Box>
      </div>
      {!canReadMembersPerm && (
        <Box>{token && <Navigate to={'../profile'} />}</Box>
      )}
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import Box from '../box/Box';
import Title from '../title/Title';
import { colors } from '../../constant';
import Icon from '../icon/Icon';
import Text from '../text/Text';
import LightTooltip from '../tooltip/Tooltip';

function formatNumber(number) {
  if (number >= 1000000000) {
    // greater than or equal to 1 billion
    return (number / 1000000000).toFixed(1) + ' B'; // divide by 1 billion, round to 1 decimal place, and add 'B'
  } else if (number >= 1000000) {
    // greater than or equal to 1 million but less than 1 billion
    return (number / 1000000).toFixed(1) + ' M'; // divide by 1 million, round to 1 decimal place, and add 'M'
  } else {
    return number; // return the number as is
  }
}

export const CardWrap = styled(Box)``;

const Status = styled(Box)``;

export const CardSimple = ({
  children,
  mainNumber,
  text,
  statusNumber = '',
  icon,
  message,
  measuringUnit = '',
  formattedValue = ''
}) => {
  const [statusNumberSymb, setStatusNumberSymb] = useState('');
  const [newStatusNumber, setNewStatusNumber] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const cardWrapRef = useRef(null);

  useEffect(() => {
    if (statusNumber?.toString().includes(' ')) {
      setNewStatusNumber(+statusNumber?.split(' ')[0]);
      setStatusNumberSymb(' ' + statusNumber?.split(' ')[1]);
    } else {
      setNewStatusNumber(statusNumber);
    }
  }, [statusNumber]);

  // TODO this whole thing needs refactoring
  function formatMainNumber(mainNumber) {
    let newMainNumber = mainNumber.toString();
    if (newMainNumber?.includes(',')) {
      newMainNumber = newMainNumber.replaceAll(',', '');
    }
    newMainNumber = newMainNumber.replace('-', '');
    newMainNumber = formatNumber(+newMainNumber);
    return newMainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const tooltipWidth = cardWrapRef?.current?.offsetWidth - 30 + 'px';
  return (
    <CardWrap
      bgcolor="white"
      boxshadow="0px 2px 4px rgba(0, 0, 0, 0.0999235)"
      minheightsize="35px"
      p="20px"
      borderradius="4px"
      ref={cardWrapRef}
      relative="true"
    >
      {children}

      <Box display="flex" alignitems="center">
        <Title
          fontSize="18px"
          color={colors.black}
          fontWeight="500"
          lineheight="normal"
        >
          {formattedValue
            ? formattedValue
            : mainNumber
            ? formatMainNumber(mainNumber)
            : '--'}
        </Title>

        {mainNumber && measuringUnit?.length > 0 && (
          <Text
            ml="5px"
            fontSize="18px"
            color={colors.black}
            fontWeight="500"
            lineheight="normal"
          >
            {measuringUnit}
          </Text>
        )}

        {Boolean(newStatusNumber) && (
          <Status
            borderradius="4px"
            p="2px 5px"
            ml="5px"
            display="flex"
            alignitems="center"
            color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
            bgcolor={
              newStatusNumber >= 0 ? 'rgba(23, 209, 79, 10%)' : '#FAE5E5'
            }
          >
            {icon && (
              <Icon
                display="flex"
                width="8px"
                height="8px"
                mr="5px"
                icon={newStatusNumber >= 0 ? 'arrow_up' : 'arrow_down_sec'}
              ></Icon>
            )}
            <Text
              fontSize="14px"
              fontWeight="500"
              color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
            >
              {newStatusNumber &&
                formatNumber(
                  newStatusNumber?.toString().replace('-', '')
                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + statusNumberSymb}
            </Text>
          </Status>
        )}
      </Box>

      <LightTooltip
        maxWidth={tooltipWidth}
        minWidth={tooltipWidth}
        placement="bottom-start"
        title={message}
        message={message}
        open={openTooltip}
        sx={{
          '& .MuiTooltip-tooltip': {
            marginLeft: '-20px'
          }
        }}
      >
        <Box display="flex" alignitems="center" mt="7px">
          <Text fontSize="14px" mb="5px" color={colors.gray40}>
            {text}
          </Text>

          <Box
            onMouseEnter={() => setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <Icon icon="info_icon" width="11px" ml="5px" mb="1px" />
          </Box>
        </Box>
      </LightTooltip>
    </CardWrap>
  );
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  createEditOrgInitialValues,
  createEditOrgValidationSchema
} from '../helpers';
import {
  checkIfOrganizationExists,
  createOrEditOrganization
} from '../../../../endpoints';
import {
  CloseModalImg,
  ModalBasic,
  ModalFooter,
  ModalHeader
} from '../../../../../common/modal/Modal';
import Title from '../../../../../common/title/Title';
import Button from '../../../../../common/button/Button';
import FormInput from '../../../../../common/form-input';
import CloseModalIcon from '../../../../../../assets/icons/x.svg';
import useDebounce from '../../../../../../hooks/use-debounce';
import { colors } from '../../../../../constant';
import { CheckingLoading } from '../styles';
import { showToast } from '../../../../../../hooks/show-toast';

export default function AddOrEditOrganizationModal({
  isEditing,
  close,
  modalWidth = '530px',
  formData,
  show,
  onSubmitFinish,
  existingOrganizationName = ''
}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [
    checkingIfOrganizationExists,
    setCheckingIfOrganizationExists
  ] = useState(false);

  const formik = {
    ...useFormik({
      validationSchema: createEditOrgValidationSchema,
      initialValues: existingOrganizationName
        ? { organizationName: existingOrganizationName }
        : createEditOrgInitialValues,
      enableReinitialize: true,
      onSubmit: (fieldValues, actions) => handleSubmit(fieldValues, actions)
    })
  };

  const debouncedSearchTerm = useDebounce(
    formik?.values?.organizationName,
    500
  );

  const handleSubmit = () => {
    setIsLoading(true);
    createOrEditOrganization(formik?.values?.organizationName, isEditing).then(
      ([res]) => {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        if (res?.success) {
          close();
          onSubmitFinish();
          formData?.setFieldValue(
            'organizationName',
            formik?.values?.organizationName
          );
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (formik?.values?.organizationName?.length)
      formik?.setFieldTouched('organizationName', true);
    if (existingOrganizationName !== formik?.values?.organizationName) {
      checkIfOrganizationExists(formik?.values?.organizationName).then(
        ([res]) => {
          if (res?.data?.exists) {
            setDisabled(true);
            formik.setFieldError(
              'organizationName',
              t('organizationNameExistsError')
            );

            setIsLoading(false);
          } else {
            setDisabled(false);
          }
          setCheckingIfOrganizationExists(false);
        }
      );
    } else {
      setCheckingIfOrganizationExists(false);
      setDisabled(true);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      formik?.resetForm();
    };
  }, [show]);

  return (
    <ModalBasic style={{ width: modalWidth }} open={show} closeModal={close}>
      <>
        <ModalHeader>
          <Title fontSize="16px" fontWeight="500" color={colors.black}>
            {isEditing ? t('editOrganization') : t('addOrganization')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>
        <Title fontSize="13px" m="-10px 0 25px 0">
          {isEditing ? t('editOrgInstruction') : t('addOrgInstruction')}
        </Title>
        <FormInput
          label={t('nameAsterisk')}
          name="organizationName"
          formik={formik}
          onChange={e => {
            formik.setFieldValue('organizationName', e.target.value);
            setCheckingIfOrganizationExists(true);
          }}
          isRequired
          filled
          placeholder={t('organizationNamePlaceholder')}
          onBlur={() => ''}
          inputMarginBottom="0"
          errorTop="calc(100% - -7px)"
        />
        <CheckingLoading>
          {checkingIfOrganizationExists &&
            formik?.isValid &&
            t('checkingOrganization')}
          &nbsp;
        </CheckingLoading>
        <ModalFooter
          mt="-20px"
          width="100%"
          display="flex"
          alignitems="center"
          justifycontent={'flex-end'}
        >
          <Button
            onClick={close}
            cancel
            mr="20px"
            style={{ marginTop: '25px' }}
            type="button"
            className={'cancel'}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            primary
            style={{ marginTop: '25px' }}
            disabled={
              !formik.isValid ||
              !formik?.dirty ||
              disabled ||
              checkingIfOrganizationExists
            }
            isLoading={isLoading}
            w="fit-content"
          >
            {isEditing ? t('save') : t('addOrganizationButton')}
          </Button>
        </ModalFooter>
      </>
    </ModalBasic>
  );
}

AddOrEditOrganizationModal.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  modalWidth: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmitFinish: PropTypes.func.isRequired,
  existingOrganizationName: PropTypes.string.isRequired
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../../../constants/breakpoints';

import FormInput from '../../../../common/form-input';
import Icon from '../../../../common/icon/Icon';

import {
  BoldDescription,
  KeysAndDescriptionContainer,
  KeysDescription,
  KeysTitle
} from './helpers';

const KeysAndDescription = ({ formik, email }) => {
  const { t } = useTranslation();
  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  return (
    <KeysAndDescriptionContainer>
      <KeysTitle>{t('enterS3RootAccessKeys')}</KeysTitle>
      <KeysDescription>
        {t('enterS3RootAccessKeysDescriptionStart')}{' '}
        <BoldDescription>{email}</BoldDescription>.{' '}
        {t('enterS3RootAccessKeysDescriptionEnd')}
      </KeysDescription>
      <FormInput
        extraClassName="modified"
        extraIcon={
          <Icon
            display={formik.values?.accessKey?.length > 0 ? 'initial' : 'none'}
            onClick={() => setShowAccessKey(prev => !prev)}
            icon={showAccessKey ? 'close_eye' : 'view'}
          />
        }
        inputType={showAccessKey ? 'text' : 'password'}
        inputMarginBottom={formik?.errors?.['accessKey'] ? '30px' : '20px'}
        label={t('accessKeyLabel')}
        name="accessKey"
        formik={formik}
        isMobile={isMobile}
      />
      <FormInput
        extraClassName="modified"
        extraIcon={
          <Icon
            display={formik.values?.secretKey?.length > 0 ? 'initial' : 'none'}
            onClick={() => setShowSecretKey(prev => !prev)}
            icon={showSecretKey ? 'close_eye' : 'view'}
          />
        }
        inputType={showSecretKey ? 'text' : 'password'}
        inputMarginBottom={formik?.errors?.['secretKey'] ? '30px' : '0px'}
        label={t('secretKeyLabel')}
        name="secretKey"
        formik={formik}
        isMobile={isMobile}
      />
    </KeysAndDescriptionContainer>
  );
};

export default KeysAndDescription;

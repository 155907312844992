import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import CloseModalIcon from '../../../assets/icons/x.svg';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import Input, { ErrorMessage } from '../../common/input/Input';
import Button from '../../common/button/Button';
import Title from '../../common/title/Title';
import { colors } from '../../constant';

export function CreateFolderModal({ open, onSubmit, displayLoader, close }) {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [specialCharsError, setSpecialCharsError] = useState('');
  const [folderName, setFolderName] = useState('');
  const {
    register,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });

  const onChange = e => {
    setFolderName(e.target.value);
  };

  useEffect(() => {
    if (folderName.length === 0) {
      setError(t('folderNameRequired'));
    } else if (
      ['%', '<', '>', '/'].some(item => folderName.indexOf(item) > -1)
    ) {
      setSpecialCharsError(t('specialCharsError'));
    } else {
      setError('');
      setSpecialCharsError('');
    }
  }, [folderName, t]);

  useEffect(() => {
    if (!open) {
      setFolderName('');
      setSpecialCharsError('');
    }
    if (open) {
      reset();
    }
  }, [open]);

  const handleSubmit = e => {
    e.preventDefault();
    if (error.length === 0 && specialCharsError.length === 0) {
      onSubmit({ name: folderName });
    }
  };

  return (
    <ModalBasic
      style={{ width: '600px' }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title color={colors.black} fontSize="16px" fontWeight="500">
            {t('createFolder')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <Input
                size="0"
                width="100%"
                fontSize="14px"
                borderColor={specialCharsError !== '' && colors.red}
                name="name"
                value={folderName}
                onChange={onChange.bind(this)}
                className={errors.folderName && 'not-valid'}
                {...register('folderName', {
                  onChange: onChange.bind(this),
                  required: true,
                  maxLength: 63
                })}
              />
              <label
                htmlFor="name"
                className={folderName.length > 0 ? 'filledField' : ''}
              >
                {t('folderName')}*
              </label>
              {specialCharsError !== '' && (
                <ErrorMessage>{specialCharsError}</ErrorMessage>
              )}
              {errors.folderName?.type === 'required' && (
                <ErrorMessage>{t('folderNameRequired')}</ErrorMessage>
              )}
              {errors.folderName?.type === 'maxLength' && (
                <ErrorMessage>{t('folderNameMaxLengthError')}</ErrorMessage>
              )}
            </div>
          </form>
        </ModalBody>

        <ModalFooter display="flex" justifycontent="flex-end">
          <Button onClick={close} className="cancel" mr="10px">
            {t('cancel')}
          </Button>
          <Button
            isLoading={displayLoader}
            w="fit-content"
            onClick={handleSubmit}
            primary
            disabled={
              !isValid ||
              errors.folderName ||
              folderName.length === 0 ||
              specialCharsError !== ''
            }
          >
            {t('create')}
          </Button>
        </ModalFooter>
      </div>
    </ModalBasic>
  );
}

CreateFolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  displayLoader: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../common/modal/Modal';
import Input, {
  BucketNameWrapper,
  ErrorMessage
} from '../../common/input/Input';
import Button from '../../common/button/Button';
import CloseModalIcon from '../../../assets/icons/x.svg';
import Title from '../../common/title/Title';
import { mobile, xsmobile } from '../../../constants/breakpoints';

import Box from '../../common/box/Box';
import Line from '../../common/line/Line';
import Text from '../../common/text/Text';
import LightTooltip from '../../common/tooltip/Tooltip';
import { colors } from '../../constant';
import { Dropdown } from '../../common/select/Dropdown';
import { showToast } from '../../../hooks/show-toast';
import { LabelInput } from '../../common/label/LabelInput';
import { ButtonToggle } from '../../common/button/ButtonToggle';
import { bucketExists, getAllBucketsNames, getRegions } from '../endpoints';

import { getFlagUrl, lastTooltipPopperProps } from './helpers';

export default function CreateBucketModal({
  open,
  close,
  onSubmit,
  displayLoader
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });
  const isXSMobile = useMediaQuery({ query: `(max-width: ${xsmobile}px)` });
  const { userData } = useStoreState(actions => actions.user);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });
  const [bucketExistsError, setBucketExistsError] = useState(false);

  const [bucketNames, setBucketNames] = useState({
    data: undefined,
    success: false,
    message: ''
  });

  const [error, setError] = useState('');

  const [bucketName, setBucketName] = useState('');
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedStoreLogs, setSelectedStoreLogs] = useState('');
  const [prefix, setPrefix] = useState('');
  const [versioninStatus, setVersioningStatus] = useState(null);
  const [isVersioningToggled, setIsVersioningToggled] = useState(false);
  const handleIsVersioningToggled = () =>
    setIsVersioningToggled(prev => {
      if (prev) {
        setIsLockingToggled(!prev);
        setVersioningStatus(null);
      } else {
        setVersioningStatus('Enabled');
      }
      return !prev;
    });

  const [isLoggingToggled, setIsLoggingToggled] = useState(false);
  const handleIsLoggingToggled = () => {
    if (!isLoggingToggled) {
      setPrefix('');
      setSelectedStoreLogs('');
    }
    setIsLoggingToggled(prev => !prev);
    reset();
  };

  const [isLockingToggled, setIsLockingToggled] = useState(false);
  const handleIsLockingToggled = () =>
    setIsLockingToggled(prev => {
      if (!prev) {
        setIsVersioningToggled(!prev);
        setVersioningStatus('Enabled');
      }
      return !prev;
    });

  useEffect(() => {
    if (!open) {
      setBucketName('');
      setSelectedRegion('');
      setSelectedStoreLogs('');
      setPrefix('');
      setIsVersioningToggled(false);
      setVersioningStatus(null);
      setIsLoggingToggled(false);
      setIsLockingToggled(false);
      setError('');
      setBucketExistsError(false);
    }

    if (open) {
      getAllBucketNames();
      getAllRegions();
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (!bucketNames.data || bucketName.length === 0) {
      setError('');
      return;
    }
    if (
      bucketName.length < 3 ||
      bucketName.length > 63 ||
      !bucketName.match(
        '(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})\\.){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$'
      )
    ) {
      setError(t('bucketNameNotValid'));
    } else {
      setError('');
    }
  }, [bucketName]);

  /**
   * Get all bucket names
   */
  const getAllBucketNames = () => {
    getAllBucketsNames().then(([res]) => {
      if (res.success) {
        setBucketNames({
          data: res.data,
          success: res.success,
          message: res?.code || res?.Code,
          params: res?.params || null
        });
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
      }
    });
  };

  /**
   * Get all regions
   */
  const getAllRegions = () => {
    getRegions(userData.Id).then(([res]) => {
      if (res.success) {
        setRegions(res.data);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
      }
    });
  };

  const handleFormSubmit = () => {
    if (error.length === 0) {
      const Logging = isLoggingToggled
        ? {
            Prefix: prefix,
            TargetBucket: selectedStoreLogs
          }
        : null;
      const createBucketData = {
        Name: bucketName,
        Region: {
          Region: selectedRegion
        },
        BucketSettings: {
          Locking: isVersioningToggled ? isLockingToggled : false,
          Versioning: versioninStatus,
          Logging
        }
      };
      onSubmit(createBucketData);
    }
  };

  const handleBucketNameChange = e => {
    setBucketExistsError(false);
    setBucketName(e.target.value);
  };

  const handlePrefixChange = e => {
    setPrefix(e.target.value);
  };

  const getRegionsItems = () => {
    const regionItems = [];
    for (let region of regions) {
      let items = [];
      for (const item of region.regions) {
        const index = region.regions.indexOf(item);
        const isLastIndex = index === region?.regions?.length - 1;
        const isLocationOverflow = item.location.length >= 16;
        const isSlugOverflow = item.slug.length > 25;
        const isURLOverflow = item.URL.length > 37;
        items.push(
          <MenuItem
            key={item.region}
            value={item.region}
            style={{ padding: '0', margin: '0 10px', borderRadius: '4px' }}
          >
            <InnerMenuWrapper>
              <Box display="flex" m="0 10px 0 0" p="0" alignitems="center">
                <Box
                  display="inline-flex"
                  alignitems="center"
                  justifycontent="center"
                >
                  <RegionFlag
                    src={require(`../../../assets/flags/regions/${getFlagUrl(
                      item.region
                    )}.svg`)}
                    alt="Region flag"
                  />
                  <LightTooltip
                    placetop={isLastIndex ? 'top' : undefined}
                    PopperProps={lastTooltipPopperProps}
                    title={isLocationOverflow ? item.location : null}
                  >
                    <RegionLocation>{item.location}</RegionLocation>
                  </LightTooltip>
                </Box>
                <Box>
                  <LightTooltip
                    placetop={isLastIndex ? 'top' : undefined}
                    PopperProps={lastTooltipPopperProps}
                    title={isSlugOverflow ? item.slug : null}
                  >
                    <RegionSlug
                      className={
                        item?.URL.length > 33
                          ? 'region__slug__long'
                          : 'region__slug'
                      }
                    >
                      {item.slug}
                    </RegionSlug>
                  </LightTooltip>
                </Box>
              </Box>
              <Box overflow="hidden">
                <LightTooltip
                  placetop={isLastIndex ? 'top' : undefined}
                  PopperProps={lastTooltipPopperProps}
                  title={isURLOverflow ? item.URL : null}
                >
                  <RegionSlug maxWidth="270px">{item.URL}</RegionSlug>
                </LightTooltip>
              </Box>
            </InnerMenuWrapper>
          </MenuItem>
        );
      }

      regionItems.push([
        <RegionCountry key="regionCountry" w="100%">
          {region.country}
        </RegionCountry>,
        items,
        <Line
          key="line"
          display={
            regions[regions.length - 1].country === region.country
              ? 'none'
              : 'block'
          }
        />
      ]);
    }
    return regionItems;
  };

  const handleChange = event => {
    setSelectedRegion(event.target.value);
  };

  const handleStoreLogsChange = event => {
    setSelectedStoreLogs(event.target.value);
  };

  const bucketNameExist = e => {
    if (!errors.bucketName && e.target.value.length > 0) {
      bucketExists(e.target.value).then(([res, error]) => {
        if (error.response.status !== 404) {
          setBucketExistsError(true);
        } else {
          setBucketExistsError(false);
        }
      });
    }
  };

  const marginBottom = () => {
    if (errors.bucketName?.type === 'pattern') {
      if (isXSMobile) {
        return '68px';
      } else if (isMobile) {
        return '48px';
      } else {
        return '35px';
      }
    }
    return '30px';
  };

  return (
    <ModalBasic
      style={{
        width: '600px',
        overflowy: 'auto',
        maxHeight: '74vh',
        mt: '60px'
      }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <div>
        <ModalHeader>
          <Title color={colors.black} fontSize="16px" fontWeight="500">
            {t('createBucket')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <BucketNameWrapper marginBottom={marginBottom}>
              <div className="input-wrapper">
                <Input
                  size="0"
                  marginBottom="0"
                  width="100%"
                  fontSize="14px"
                  name="bucketName"
                  value={bucketName ?? ''}
                  className={errors.bucketName ? 'not-valid' : ''}
                  {...register('bucketName', {
                    onChange: handleBucketNameChange,
                    onBlur: bucketNameExist,
                    required: true,
                    minLength: 3,
                    maxLength: 63,
                    pattern: /(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})\.){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$/g
                  })}
                />
                <label
                  htmlFor="name"
                  className={bucketName.length > 0 ? 'filledField' : ''}
                >
                  {t('bucketName')}*
                </label>
                {errors.bucketName?.type === 'minLength' && (
                  <ErrorMessage className="error-message--nospace">
                    {t('bucketNameMinLengthError')}
                  </ErrorMessage>
                )}
                {errors.bucketName?.type === 'maxLength' && (
                  <ErrorMessage className="error-message--nospace">
                    {t('bucketNameMaxLengthError')}
                  </ErrorMessage>
                )}
                {errors.bucketName?.type === 'pattern' && (
                  <ErrorMessage className="error-message--nospace">
                    {t('bucketNamePatternError')}
                  </ErrorMessage>
                )}
                {errors.bucketName?.type === 'required' && (
                  <ErrorMessage className="error-message--nospace">
                    {t('bucketNameRequiredError')}
                  </ErrorMessage>
                )}
                {bucketExistsError && !errors.bucketName && (
                  <ErrorMessage className="error-message--nospace">
                    {t('bucketNameExistsError', { bucketName: bucketName })}
                  </ErrorMessage>
                )}
              </div>

              {/*<Text color="red" fontSize="12px" hidden={error.length === 0}>{error}</Text>*/}
            </BucketNameWrapper>
            <FormControl fullWidth>
              <LabelInput id="region-label" htmlFor="region">
                {t('selectRegion')}*
              </LabelInput>
              <Dropdown
                labelId="region-label"
                id="region"
                value={selectedRegion}
                label={t('selectRegion')}
                onChange={handleChange}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'center',
                    horizontal: 'center'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  },
                  PaperProps: { sx: { maxHeight: 'calc(100vh - 80px)' } }
                }}
              >
                {getRegionsItems()}
              </Dropdown>
            </FormControl>

            <ModalBody>
              <Box display="flex" direction="column" mt="30px">
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="center"
                  w="100%"
                  mb="10px"
                >
                  <Title fontSize="16px" color="#0B293A" fontWeight="500">
                    {t('bucketVersioning')}
                  </Title>
                  <ButtonToggle
                    type="button"
                    isToggled={isVersioningToggled}
                    handle={handleIsVersioningToggled}
                  />
                </Box>

                <Text
                  mb="20px"
                  fontSize="14px"
                  fontWeight="400"
                  maxWidth="530px"
                >
                  {t('bucketVersioningDescription')}
                </Text>
              </Box>

              <Box display="flex" direction="column" mt="10px">
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="center"
                  w="100%"
                  mb="10px"
                >
                  <Title fontSize="16px" color="#0B293A" fontWeight="500">
                    {t('bucketLogging')}
                  </Title>
                  <ButtonToggle
                    type="button"
                    isToggled={isLoggingToggled}
                    handle={handleIsLoggingToggled}
                  />
                </Box>

                <Text
                  mb="20px"
                  fontSize="14px"
                  fontWeight="400"
                  maxWidth="530px"
                >
                  {t('bucketLoggingDescription')}
                </Text>

                <Box mb="20px" display={isLoggingToggled ? 'block' : 'none'}>
                  <div className="input-wrapper">
                    <Input
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="prefix"
                      className={errors.loggingPrefix ? 'not-valid' : ''}
                      {...register('loggingPrefix', {
                        onChange: handlePrefixChange,
                        required: isLoggingToggled,
                        maxLength: 256,
                        pattern: /^\S.*$/gm
                      })}
                    />
                    <label
                      htmlFor="prefix"
                      className={prefix.length > 0 ? 'filledField' : ''}
                    >
                      {t('loggingPrefix')}
                    </label>
                    {errors.loggingPrefix?.type === 'required' && (
                      <ErrorMessage>{t('loggingPrefixRequired')}</ErrorMessage>
                    )}
                    {errors.loggingPrefix?.type === 'maxLength' && (
                      <ErrorMessage>{t('loggingPrefixMaxLength')}</ErrorMessage>
                    )}
                    {errors.loggingPrefix?.type === 'pattern' && (
                      <ErrorMessage>{t('loggingPrefixPattern')}</ErrorMessage>
                    )}
                  </div>

                  <FormControl fullWidth>
                    <LabelInput id="storeLogsLabel" htmlFor="storeLogs">
                      {t('bucketToStoreLogs')}
                    </LabelInput>
                    <Dropdown
                      id="storeLogs"
                      labelId="storeLogsLabel"
                      label={t('bucketToStoreLogs')}
                      fullWidth
                      name="storeLogs"
                      value={selectedStoreLogs}
                      {...register('storeLogs', {
                        onChange: handleStoreLogsChange
                      })}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        PaperProps: { sx: { maxHeight: 230 } }
                      }}
                      sx={{
                        '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input > div': {
                          paddingBlock: '10px'
                        }
                      }}
                    >
                      {bucketNames &&
                        bucketNames.data &&
                        bucketNames.data &&
                        bucketNames.data.map((b, index) => (
                          <MenuItem key={b} value={b}>
                            <Box
                              w="100%"
                              display="flex"
                              p="0"
                              lineHeight="40px"
                            >
                              <Text fontSize="14px" fontWeight="400">
                                {b}
                              </Text>
                            </Box>
                          </MenuItem>
                        ))}
                    </Dropdown>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex" direction="column" mt="10px" mb="20px">
                <Box
                  display="flex"
                  justifycontent="space-between"
                  w="100%"
                  mb="10px"
                >
                  <Title fontSize="16px" color={colors.black} fontWeight="500">
                    {t('objectLocking')}
                  </Title>
                  <ButtonToggle
                    isToggled={isLockingToggled}
                    handle={handleIsLockingToggled}
                  />
                </Box>

                <Text
                  mb="20px"
                  fontSize="14px"
                  fontWeight="400"
                  maxWidth="530px"
                >
                  <Text fontWeight="500">({t('versioningMustBeEnabled')})</Text>
                  {t('objectLockingDescription')}
                </Text>
              </Box>
            </ModalBody>
          </ModalBody>

          <ModalFooter end="true">
            <Button
              onClick={close}
              type={'button'}
              className="cancel"
              mr="20px"
            >
              {t('cancel')}
            </Button>
            <Button
              isLoading={displayLoader}
              w="fit-content"
              type={'submit'}
              primary
              disabled={
                !isValid ||
                bucketExistsError ||
                selectedRegion?.length <= 0 ||
                (isLoggingToggled && selectedStoreLogs.length <= 0)
              }
            >
              {t('create')}
            </Button>
          </ModalFooter>
        </form>
      </div>
    </ModalBasic>
  );
}

const RegionLocation = styled(Box)`
  font-style: normal;
  font-weight: 500;
  color: #525e6b;
  margin-right: 5px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 128px;
  min-height: 25px;

  &::after {
    content: '';
    display: block;
  }
`;

const RegionSlug = styled(Box)`
  font-style: normal;
  font-weight: 400;
  color: #788089;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${props => props?.maxWidth || '185px'};
  min-height: 25px;

  &::after {
    content: '';
    display: block;
  }
`;

const RegionCountry = styled(Box)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.gray20};
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px 0 10px 15px;

  &::after {
    content: '';
    display: block;
  }
`;

const RegionFlag = styled.img`
  object-fit: cover;
  width: 19px;
  height: 12.67px;
  border: 0.1px solid #e8e8e8;
  display: inline-flex;
  margin-right: 10px;
  margin-bottom: 4px;
`;

const InnerMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  flex-wrap: wrap !important;
`;

CreateBucketModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  displayLoader: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

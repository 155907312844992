import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { mdmobile } from '../../../../../constants/breakpoints';
import { SpanFlex } from '../../helpers';
import { colors } from '../../../../constant';
import Title from '../../../../common/title/Title';
import LightTooltip from '../../../../common/tooltip/Tooltip';
import Icon from '../../../../common/icon/Icon';

const AccessKeysHeader = () => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const isMdMobile = useMediaQuery({ query: `(max-width: ${mdmobile}px)` });

  const handleClickInfoIcon = e => {
    e.stopPropagation();
    if (isMdMobile) {
      setShowTooltip(!showTooltip);
    }
  };

  const handleMouseOver = () => {
    if (!isMdMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    if (!isMdMobile) {
      setShowTooltip(false);
    }
  };

  const handleOustideTooltipClick = event => {
    if (event.target.closest('.MuiTooltip-popper')) {
      setShowTooltip(false);
    }
  };

  document.addEventListener('click', handleOustideTooltipClick, true);

  return (
    <SpanFlex>
      <Title fontWeight="500" fontSize="18px" color={colors.black}>
        {t('accessKeys')}
      </Title>
      <LightTooltip
        open={(isMdMobile && showTooltip) || (!isMdMobile && showTooltip)}
        isMobile={isMdMobile}
        title={t('accessKeysTooltip')}
        placement={'bottom-start'}
      >
        <SpanFlex
          onClick={e => handleClickInfoIcon(e)}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Icon icon="info_icon" ml="5px" width="11px" />
        </SpanFlex>
      </LightTooltip>
    </SpanFlex>
  );
};

export default AccessKeysHeader;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';

import CloseModalIcon from '../../../assets/icons/x.svg';
import {
  CloseModalImg,
  ModalBasic,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../modal/Modal';
import Input from '../input/Input';
import Box from '../box/Box';
import Text from '../text/Text';
import Title from '../title/Title';
import Button from '../button/Button';
import { colors } from '../../constant';
import { LabelInput } from '../label/LabelInput';
import { Dropdown } from '../select/Dropdown';
import { exportFormatList } from '../../../constants/export-list';

export function ExportModal({ open, close, onSubmit, isLoading }) {
  const { t } = useTranslation();

  const [selectedFormatExport, setSelectedFormatExport] = useState(
    exportFormatList[0].value
  );

  const handleChange = event => {
    setSelectedFormatExport(event.target.value);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    onSubmit(selectedFormatExport);
  };

  useEffect(() => {
    if (!open) {
      setSelectedFormatExport(exportFormatList[0].value); // Reset the selected format when modal is closed
    }
  }, [open]);

  return (
    <ModalBasic
      style={{
        width: '550px',
        overflowy: 'auto',
        maxHeight: '88vh',
        mt: '60px'
      }}
      open={open}
      closeModal={close}
      // eslint-disable-next-line react/no-children-prop
      children={<Input />}
    >
      <>
        <ModalHeader>
          <Title
            fontSize="16px"
            fontWeight="500"
            lineheight="normal"
            color={colors.black}
          >
            {t('export')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <form onSubmit={handleFormSubmit}>
          <ModalBody>
            <FormControl fullWidth>
              <LabelInput>
                <Text bgcolor={colors.white} minwidth="120px">
                  {t('selectExportFormat')}
                </Text>
              </LabelInput>
              <Dropdown
                labelId="selectFormat"
                id="selectFormat"
                defaultValue={selectedFormatExport}
                value={selectedFormatExport}
                label="selectFormat"
                onChange={handleChange}
              >
                {exportFormatList &&
                  exportFormatList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      <Box w="100%" display="flex" p="0" lineheight="40px">
                        <Text fontSize="14px" fontWeight="400">
                          {item.displayName}
                        </Text>
                      </Box>
                    </MenuItem>
                  ))}
              </Dropdown>
            </FormControl>
          </ModalBody>

          <ModalFooter end="true" mt="30px">
            <Button
              onClick={close}
              type={'button'}
              className="cancel"
              mr="20px"
            >
              {t('cancelButton')}
            </Button>
            <Button
              w="fit-content"
              isLoading={isLoading}
              type={'submit'}
              primary
            >
              {t('export')}
            </Button>
          </ModalFooter>
        </form>
      </>
    </ModalBasic>
  );
}

ExportModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

import * as Yup from 'yup';
import styled from 'styled-components';
import i18n from '../../../i18n';

export const initialValues = {
  secretKey: '',
  accessKey: ''
};

export const validationSchema = Yup.object({
  secretKey: Yup.string()
    .label('Secret Key')
    .required(i18n.t('secretKeyIsReqired')),
  accessKey: Yup.string()
    .label('Access Key')
    .required(i18n.t('accessKeyIsReqired'))
});

export const resetInitialValues = {
  confirm: ''
};

export const AccessKeysContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #f1f1f1;
  margin-top: -60px;
`;

export const AccessKeysModal = styled.div`
  margin-block: auto;
  width: ${props => (props.isMobile ? '75%' : '452px')};
  height: fit-content;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.101617);
  border-radius: 4px;
`;

export const ModalTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 27px;
  color: #0b293a;
  border-bottom: 1px solid #f1f1f1;
`;

export const ConfirmResetTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 14px;
  color: #0b293a;
  border-bottom: 1px solid #f1f1f1;
`;

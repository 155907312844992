import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../constants/breakpoints';
import Box from '../box/Box';

export const ModalBasic = ({ closeModal, children, style, open = false }) => {
  const preventClickPropagation = e => {
    e.stopPropagation();
  };

  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  return (
    <Modal
      onClose={closeModal}
      open={open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <ModalContent
        width={isMobile ? '75%' : style.width}
        height={style.height}
        maxHeight={style.maxHeight}
        overflowy={style.overflowy}
        mt={style.mt}
        onClick={preventClickPropagation}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  margin-top: ${props => props.mt};
  overflow-y: ${props => props.overflowy};
  background: ${props => (props.primary ? 'white' : 'white')};
  padding: 30px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: initial;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f6f8;
  padding-bottom: 25px;
  margin-bottom: 25px;
`;

export const ModalBody = styled(Box)`
  & .vi__wrapper {
    width: 100% !important;
  }
`;

export const ModalFooter = styled(Box)`
  ${({ center }) =>
    center && 'display: flex; justify-content: center; align-items: center;'}
  ${({ centeritems }) => centeritems && 'display: flex; align-items: center;'}
 ${({ start }) => start && 'display: flex; justify-content: flex-start'}
 ${({ end }) => end && 'display: flex; justify-content: flex-end'}
 margin-top: ${props => props.mt || ''};
 gap: ${props => (props.isMobile ? '0' : '10px')};
 flex-wrap: ${props => (props.isXsMobile ? 'wrap' : 'nowrap')};
`;

export const CloseModalImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.isMobile ? '60%' : '100%')};
  margin-right: ${props => (props.isMobile ? 0 : '20px')};
`;
ModalBasic.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  style: PropTypes.object.isRequired
};

import React from 'react';
import Box from '../common/box/Box';
import Text from '../common/text/Text';
import { useStoreState } from 'easy-peasy';

const TextAvatar = ({
  name,
  lastName,
  size,
  bgColor,
  showTextAvatar,
  round
}) => {
  const { theme } = useStoreState(state => state.theme);

  const capitalizeFirstLetter = (text, nrOfLetters) => {
    return text.slice(0, nrOfLetters).toUpperCase();
  };

  if (typeof name !== 'string') {
    throw new Error('Name must be only a string');
  }

  let abbr = '';
  if (!lastName || lastName.length === 0) {
    const childrenElements = name.split(' ');
    if (childrenElements.length > 1) {
      abbr =
        capitalizeFirstLetter(childrenElements[0], 1) +
        capitalizeFirstLetter(childrenElements[1], 1);
    } else if (childrenElements.length === 1) {
      abbr = capitalizeFirstLetter(childrenElements[0], 2);
    } else {
      throw new Error('Children must not be empty');
    }
  } else {
    abbr = capitalizeFirstLetter(name, 1) + capitalizeFirstLetter(lastName, 1);
  }

  if (typeof size !== 'number') {
    throw new Error('Size must be an integer');
  }
  return (
    <Box
      borderradius={round}
      center="true"
      display={showTextAvatar ? 'flex !important' : 'none !important'}
      minwidth={size + 'px'}
      maxwidth="100%"
      heightsize={size + 'px'}
      bgcolor={bgColor}
    >
      <Text
        color={theme.themes?.colorPrimary}
        fontSize={size / 2 + 'px'}
        fontWeight="bold"
      >
        {abbr}
      </Text>
    </Box>
  );
};

export default TextAvatar;

import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useMediaQuery } from 'react-responsive';

import { xsmobile } from '../../../../../constants/breakpoints';
import Line from '../../../../common/line/Line';
import Button from '../../../../common/button/Button';
import Icon from '../../../../common/icon/Icon';
import Box from '../../../../common/box/Box';
import Text from '../../../../common/text/Text';
import { colors } from '../../../../constant';

const FileActions = ({
  canDownloadFilePerm,
  theme,
  onDownloadFileClick,
  onCopyPathClick,
  canDeleteFilePerm,
  deleteClicked,
  onDeleteClick,
  onCancelDeleteClick,
  onDeleteConfirmClick,
  deleteLoader
}) => {
  const { t } = useTranslation();
  const isXSMobile = useMediaQuery({ query: `(max-width: ${xsmobile}px)` });

  return (
    <Box display="flex" direction="column">
      <Line m="20px 0" />
      {canDownloadFilePerm && (
        <Button
          outlined
          m="0 0 20px"
          center="true"
          hoverColor={theme.themes?.colorPrimary}
          onClick={onDownloadFileClick}
        >
          <Icon width="15px" icon="download" mr="15px" />
          {t('downloadFile')}
        </Button>
      )}
      <Button
        outlined
        m="0 0 20px"
        center="true"
        hoverColor={theme.themes?.colorPrimary}
        onClick={onCopyPathClick}
      >
        <Icon width="15px" icon="copy" mr="15px" />
        {t('copyPath')}
      </Button>
      {canDeleteFilePerm && (
        <Button
          outlined
          center="true"
          onClick={onDeleteClick}
          className="delete delete-with-border"
        >
          <Icon width="15px" icon="deleteOutlined" mr="15px" />
          {t('deleteFile')}
        </Button>
      )}
      {canDeleteFilePerm && (
        <Box display={deleteClicked ? 'flex' : 'none'} direction="column">
          <Text
            fontSize="14px"
            centeritems="true"
            m="20px 0"
            className="warning-text"
          >
            <Text fontWeight="500">{t('warning')}: </Text>
            {t('confirmDelete')}
          </Text>
          <Box display="flex" alignitems="center">
            <Button
              outlined
              mr="20px"
              w={isXSMobile ? 'fit-content' : '100%'}
              onClick={onCancelDeleteClick}
              className="cancel"
              isMobile={isXSMobile}
            >
              {t('cancel')}
            </Button>
            <Button
              color={colors.white}
              bordercolor="transparent"
              bgcolor={colors.red}
              minwidth={isXSMobile ? '' : '154px'}
              onClick={onDeleteConfirmClick}
              className="delete"
              isLoading={deleteLoader}
              isMobile={isXSMobile}
            >
              {t('delete')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FileActions;

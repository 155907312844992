import React from 'react';
import Box from '../common/box/Box';
import Title from '../common/title/Title';
import Text from '../common/text/Text';
import Button from '../common/button/Button';
import { colors } from '../constant';
import { useTranslation } from 'react-i18next';

export function EmptyBucketsView({
  onCreateBucketClick,
  canCreateBucketsPerm
}) {
  const { t } = useTranslation();
  /**
   * Create bucket.
   */
  const showCreateBucketModal = () => {
    onCreateBucketClick();
  };

  return (
    <Box maxwidth="540px" m="50px auto">
      <Box
        display="flex"
        direction="column"
        alignitems="center"
        justifycontent="center"
      >
        <Title fontSize="20px" color={colors.black} fontWeight="500">
          {t('noBucketsYet')}
        </Title>
        {canCreateBucketsPerm && (
          <>
            <Text m="0 0 30px" fontSize="14px" color={colors.bodyColor}>
              {t('createBucketAndStartUploading')}
            </Text>

            <Button
              primary
              minHeight="40px"
              onClick={showCreateBucketModal}
              m="0 30px"
            >
              {t('createBucket')}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
